import React, { useEffect, useState } from "react";
import ViewHeader from "../components/ViewHeader";
import { useSelector } from "react-redux";
import SmallCard from "../components/SmallCard";
import "./styles/Game.css";
import GraphCard from "../components/GraphCard";

const Game = () => {
  const [pathLength, setPathLength] = useState(0);
  const game = useSelector((state) => state.user.game);
  const childUsername = useSelector((state) => state.user.childUsername);
  const level = useSelector((state) => state.user.level);
  const levels = useSelector((state) => state.user.levels);
  const graphData = useSelector((state) => state.user.graphData);
  const sessionMinutesPlayed = useSelector(
    (state) => state.user.sessionMinutesPlayed
  );

  useEffect(() => {
    let xPoints = graphData.map((data) => data.x);
    let yPoints = graphData.map((data) => data.y);
    setPathLength(getCopPathLength(xPoints, yPoints));
  }, [graphData]);

  /*
  used for an early euclidean distance calculation. 
  need the data points in two arrays all the x point values and all the y values. 
  Could just have an array of JSON objects with x and y in to simplify this.

  we will need to scale up the final score value 
  as we don't want to present to them loads of decimal places. 
  It will be simple just multiply the final value by 100
  */
  const getCopPathLength = (x, y) => {
    let copPl = 0;
    if (x.length !== y.length) {
      console.log("Don't match");
      return false;
    }
    for (let i = 0; i < x.length; i++) {
      if (i === 0) {
        continue;
      }
      let ax = x[i] - x[i - 1];
      ax = ax * ax;
      let ay = y[i] - y[i - 1];
      ay = ay * ay;
      let result = ax + ay;
      copPl += Math.sqrt(result);
      return (copPl * 100).toFixed(2);
    }
  };

  return (
    <div className="Game">
      <ViewHeader title={game} subtitle={`${childUsername} stats`} />
      <div className="game-content">
        <SmallCard
          title="Played Levels"
          data={levels.length}
          footer="2 levels up"
        />
        <SmallCard title="Score" data={level.LevelResult} footer="10% down" />
        {/* <SmallCard title="Score" data={pathLength} footer="10% down" /> */}
        <SmallCard
          title="Minutes Played"
          data={sessionMinutesPlayed}
          footer="20% up"
        />
      </div>
      <div className="game-graphs">
        <GraphCard />
      </div>
    </div>
  );
};

export default Game;
