import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { handleChildId } from "../state/userSlice";
import "./styles/RecentPlayersCard.css";

const RecentPlayersCard = () => {
  const children = useSelector((state) => state.user.children);
  const dispatch = useDispatch();

  return (
    <div className="RecentPlayersCard card">
      <div className="header">
        <p className="container-title">Players</p>
        <span className="container-subtitle">Recent players</span>
      </div>
      <div className="recent-players">
        {children.slice(0, 5).map((child, i) => (
          <Link
            key={i}
            to={{
              pathname: `/player/${child.Username}`,
              state: child.Username,
            }}
            onClick={() => dispatch(handleChildId(child.Id))}
          >
            <div className="recent-player">
              <p className="icon">{child.Username.substr(0, 1)}</p>
              <p className="name">{child.Username}</p>
              <p className="hours-ago">Id {child.Id}</p>
            </div>
          </Link>
        ))}
      </div>
      <div className="footer">
        <Link to="/players">
          <p>
            See all players data{" "}
            <img src="/images/arrow.png" alt="Arrow icon" />
          </p>
        </Link>
      </div>
    </div>
  );
};

export default RecentPlayersCard;
