import React from "react";
import "./styles/LoadingDots.css";

const LoadingDots = () => {
  return (
    <div className="LoadingDots">
      <div className="dot-flashing"></div>
    </div>
  );
};

export default LoadingDots;
