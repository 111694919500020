import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { saveSingleGame, updateSingleGame } from "../state/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Constants } from "../services/urls";
import "./styles/DevelopmentModal.css";

const { URL } = Constants;

const DevelopmentModal = ({ toggle, closeModal, type, game, handleGame }) => {
  const [gameFile, setGameFile] = useState({});
  const [mediaFiles, setMediaFiles] = useState({});
  const [gameMedia, setGameMedia] = useState([]);
  const dispatch = useDispatch();
  const fileName = useRef("");
  const mediaFileName = useRef("");

  const saveChanges = () => {
    if (type === "Add Game") {
      if (game.Title !== "" && "size" in gameFile) {
        dispatch(saveSingleGame(game, gameFile, gameMedia, mediaFiles));
        close();
      }
    } else {
      updateChanges();
    }
  };

  const updateChanges = () => {
    dispatch(updateSingleGame(game, gameFile, gameMedia, mediaFiles));
    close();
  };

  const handleGameFile = (e) => {
    setGameFile(e.target.files[0]);
  };

  const handleMediaFiles = (e) => {
    let media = [];
    Array.from(e.target.files).forEach((file) => {
      media.push({
        IsVideo: 0,
        Url: file.name,
      });
    });
    setGameMedia((oldArray) => [...oldArray, ...media]);
    setMediaFiles(e.target.files);
  };

  const close = () => {
    fileName.current.value = "";
    mediaFileName.current.value = "";
    setGameFile({});
    setMediaFiles({});
    setGameMedia([]);
    closeModal();
  };

  return (
    <div
      className="DevelopmentModal AddPlayer"
      style={{ display: toggle ? "flex" : "none" }}
    >
      <div className="modal-window">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{type}</h2>
            <div className="close-modal" onClick={close}>
              <img src="/images/x.png" alt="X" />
            </div>
          </div>
          <div className="dev-form">
            <div className="form-section">
              <div className="field">
                <div className="username-container">
                  <label htmlFor="email">* Title</label>
                </div>
                <br />
                <input
                  type="text"
                  value={game.Title}
                  placeholder="Name of the game"
                  onChange={(e) => handleGame(e.target.value, "Title")}
                />
              </div>
              <div className="field">
                <label htmlFor="email">Description</label>
                <br />
                <textarea
                  placeholder="Description of the game"
                  value={game.Description}
                  onChange={(e) => handleGame(e.target.value, "Description")}
                />
              </div>
            </div>
            <div className="form-section files">
              <div className="field">
                <label htmlFor="email">
                  {type === "Add Game" ? "* " : ""}Project zip file
                </label>
                <br />
                <input
                  type="file"
                  accept=".zip"
                  ref={fileName}
                  onChange={handleGameFile}
                />
              </div>
              <div className="field">
                <label htmlFor="email">Relative path to executable file</label>
                <br />
                <input
                  value={game.Files.ExePath}
                  onChange={(e) => handleGame(e.target.value, "ExePath")}
                  placeholder="e.g. /foldername/game.exe"
                  type="text"
                  style={{ fontSize: ".8em" }}
                />
              </div>
            </div>
          </div>
          <div className="separator"></div>
          <div className="media-files">
            <div className="field long-field">
              <label htmlFor="email">Media files</label>
              <br />
              <input
                type="file"
                accept=".jpg, .png, .jpeg, .mp4, .mov, .wmv"
                ref={mediaFileName}
                multiple
                onChange={handleMediaFiles}
              />
              <br />
              <span className="multiple-files">
                You can upload multiple files
              </span>
            </div>
            <div className="the-files">
              <label htmlFor="email">Files</label>
              <div className="files-container">
                {gameMedia.length > 0 &&
                  gameMedia.map((media) => (
                    <span key={media.MediaId}>
                      <FontAwesomeIcon icon={faTrashAlt} color="#e7315f" />
                      <p>{media.Url}</p>
                    </span>
                  ))}
                {game.Media.length > 0 &&
                  game.Media.map((media) => (
                    <span key={media.MediaId}>
                      <FontAwesomeIcon icon={faTrashAlt} color="#e7315f" />
                      <a
                        href={
                          media.IsVideo
                            ? `https://www.youtube.com/watch?v=${media.Url}`
                            : `${URL}/${media.Url}`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {media.Url}
                      </a>
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button id="cancel-btn" onClick={close}>
            Cancel
          </button>
          <button id="create-btn" onClick={saveChanges}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentModal;
