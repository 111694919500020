import React from "react";
import "./styles/Toggle.css";

const Toggle = ({ setting, isOn, switchIsOn }) => {
  return (
    <div className="Toggle">
      <div className="toggle">
        <input
          type="checkbox"
          name={setting}
          className="mobileToggle"
          id={setting}
          checked={isOn}
          onChange={() => switchIsOn(isOn)}
        />
        <label htmlFor={setting}></label>
      </div>
    </div>
  );
};

export default Toggle;
