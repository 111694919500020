import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faColumns,
  // faGamepad,
  faUsers,
  faUser,
  faPowerOff,
  faHammer,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../state/userSlice";
import "./styles/Navigation.css";

export default function Navigation() {
  const userType = useSelector((state) => state.user.userType);
  const dispatch = useDispatch();

  return (
    <div className="Navigation">
      <div className="nav-container">
        <div className="header">
        <div>
            <img id="logo" src="/images/newlogo.png" alt="Logo png" />
        </div>
          {/* <h1>Therapy Reloaded</h1> */}
        </div>
        <nav>
          <ul>
            <li>
              <Link to="/">
                <FontAwesomeIcon icon={faColumns} pull="left" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/players">
                <FontAwesomeIcon icon={faUsers} pull="left" />
                <span>Players</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/games">
                <FontAwesomeIcon icon={faGamepad} pull="left" />
                <span>Games</span>
              </Link>
            </li> */}
            <li>
              <Link to="/profile">
                <FontAwesomeIcon icon={faUser} pull="left" />
                <span>Profile</span>
              </Link>
            </li>
            {userType === "dev" && (
              <li>
                <Link to="/development">
                  <FontAwesomeIcon icon={faHammer} pull="left" />
                  <span>Development</span>
                </Link>
              </li>
            )}
          </ul>
        </nav>
        <div className="logout" onClick={() => dispatch(logout())}>
          <FontAwesomeIcon icon={faPowerOff} pull="left" />
          <span>Log out</span>
        </div>
      </div>
    </div>
  );
}
