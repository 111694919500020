import React from "react";
import Toggle from "./Toggle";
import { updateChildOptions } from "../state/userSlice";
import { useDispatch, useSelector } from "react-redux";

const ToggleOption = ({ setting, description, isOn, option }) => {
  const childId = useSelector((state) => state.user.childId);
  const options = useSelector((state) => state.user.childOptions);
  const dispatch = useDispatch();

  return (
    <div className="child-setting">
      <div>
        <span className="info">{setting}</span>
        <Toggle
          setting={setting}
          isOn={isOn}
          switchIsOn={(opt) =>
            dispatch(
              updateChildOptions(childId, { ...options, [option]: !opt })
            )
          }
        />
      </div>
      <span className="setting-description">{description}</span>
    </div>
  );
};

export default ToggleOption;
