import React from "react";
import "./styles/CheckboxOption.css";

export const CheckboxOption = ({ title, checked, onChecked }) => {
  return (
    <div>
      <label className="container" onClick={onChecked}>
        {title}
        <input type="checkbox" checked={checked} />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};
