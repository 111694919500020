import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadSessions } from "../state/userSlice";
import SessionEntry from "./SessionEntry";
import "./styles/SessionsCard.css";

const SessionsCard = () => {
  const [itemsToShow, setItemsToShow] = useState(5);
  const [isShowingAll, setIsShowingAll] = useState(false);
  const sessions = useSelector((state) => state.user.sessions);
  const childId = useSelector((state) => state.user.childId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSessions(childId));
  }, [dispatch, childId]);

  useEffect(() => {
    setItemsToShow(() => {
      return isShowingAll ? sessions.length : 5;
    });
  }, [isShowingAll, sessions]);

  const toggleShowList = () => {
    setIsShowingAll(!isShowingAll);
  };

  return (
    <div className="SessionsCard card">
      <div className="header">
        <p className="container-title">Sessions</p>
        <span className="container-subtitle">All played sessions</span>
      </div>
      <div className="sessions-header">
        <p>Date</p>
        <p>Session</p>
        <p>Score</p>
      </div>
      <div className="games-list">
        {sessions.slice(0, itemsToShow).map((session) => (
          <SessionEntry key={session.Id} session={session} />
        ))}
        <div className="footer" onClick={toggleShowList}>
          <p>
            {isShowingAll ? "See less sessions" : "See more sessions"}{" "}
            <img
              src="/images/arrow.png"
              alt="Arrow icon"
              style={{ transform: `rotate(${isShowingAll ? "-" : ""}90deg)` }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default SessionsCard;
