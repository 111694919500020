import React, { useState } from "react";
import PlayerEntry from "./PlayerEntry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import "./styles/PlayersCard.css";
import { useSelector } from "react-redux";
import AddPlayer from "./AddPlayer";
import SharePlayerModal from "./SharePlayerModal";

const PlayersCard = () => {
  const children = useSelector((state) => state.user.children);
  const [toggleAddPlayer, setToggleAddPlayer] = useState(false);
  const [toggleSharePlayer, setToggleSharePlayer] = useState(false);
  const [userShare, setUserShare] = useState({});

  const showAddPlayer = () => {
    setToggleAddPlayer(true);
  };

  const closeModal = () => {
    setToggleAddPlayer(false);
  };

  return (
    <div className="PlayersCard">
      <div className="players-header">
        <p>Name</p>
        <p>Last time played</p>
        <p>Minutes Played</p>
        <div className="add-player" onClick={showAddPlayer}>
          <FontAwesomeIcon icon={faUserPlus} />
          <span>Add Player</span>
        </div>
      </div>
      <div className="players-list">
        {children
          .filter((child) => child.IsActive)
          .map((child, i) => (
            <PlayerEntry
              player={child}
              key={i}
              sharePlayer={(user) => {
                setUserShare(user);
                setToggleSharePlayer(true);
              }}
            />
          ))}
        {children
          .filter((child) => !child.IsActive)
          .map((child, i) => (
            <span className="inactive-players" key={i}>
              <h2>Inactive Players</h2>
              <PlayerEntry
                player={child}
                key={i}
                sharePlayer={(user) => {
                  setUserShare(user);
                  setToggleSharePlayer(true);
                }}
              />
            </span>
          ))}
      </div>
      <SharePlayerModal
        toggle={toggleSharePlayer}
        userShare={userShare}
        closeModal={() => setToggleSharePlayer(false)}
      />
      <AddPlayer toggle={toggleAddPlayer} closeModal={closeModal} />
    </div>
  );
};

export default PlayersCard;
