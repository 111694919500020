import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
// import { faGlobe, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { loadLevels, handleGame, handleChildId } from "../state/userSlice";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import "./styles/UserCard.css";

function UserCard() {
  const { username, children, allSessions, childId, game } = useSelector(
    (state) => ({
      game: state.user.game,
      username: state.user.username,
      children: state.user.children,
      allSessions: state.user.allSessions,
      childId: state.user.childId,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const selectSession = (session) => {
    dispatch(loadLevels(childId, session.sessionId));
    dispatch(handleGame(session.Title));
  };

  return (
    <div className="UserCard">
      <div className="header">
        <div className="profile-info">
          <p>Account Name:</p>
          <h3>{username}</h3>
          {/* <h4>Admin</h4> */}
        </div>
      </div>
      <div className="profile-data">
        <div>
          <p>Players</p>
          <span>{children.length}</span>
        </div>
        {/* <div>
          <p>Games</p>
          <span>3</span>
        </div>
        <div>
          <p>Boards</p>
          <span>2</span>
        </div> */}
      </div>
      {/* <span className="separator"></span> */}
      <div className="profile-section">
        {/* <p className="subtitle">Organisation</p> */}
        <div className="content-container">
          <div>
            {/* <FontAwesomeIcon icon={faBuilding} />
            <span className="info">N/A</span> */}
          </div>
          <div>
            {/* <FontAwesomeIcon icon={faGlobe} /> */}
            {/* <span> */}
              {/* <a href="https://ubitheraplay.com">N/A</a> */}
            {/* </span> */}
          </div>
          {/* <div>
            <FontAwesomeIcon icon={faPhoneAlt} />
            <span>0202020200</span>
          </div> */}
        </div>
      </div>
      <span className="separator"></span>
      <div className="profile-section">
        <p className="subtitle">Recent Activity</p>
        {allSessions.length > 0 ? (
          <ul>
            {allSessions.slice(0, 6).map((session, i) => (
              <li key={i}>
                <span className="recent-date">
                  {new Date(session.createdAt).toLocaleDateString()}
                </span>
                {" - "}
                <Link
                  to={{
                    pathname: `/player/${session.Username}`,
                    state: session.Username,
                  }}
                  onClick={() => dispatch(handleChildId(session.Id))}
                >
                  <span className="recent-username">{session.Username}</span>
                </Link>
                <span className="recent-title">
                  {" "}
                  played{" "}
                  <Link
                    to={{
                      pathname: `/player/${session.Username}/session/${session.sessionId}`,
                      state: game,
                    }}
                    className="session-id"
                    onClick={() => selectSession(session)}
                  >
                    {session.title}
                  </Link>
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p>No recent activity found</p>
        )}
      </div>
    </div>
  );
}

export default UserCard;
