import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  handleGameSlug,
  handleGame,
  // selectLastSession,
} from "../state/userSlice";
// import PlayerOptionsMenu from "./PlayerOptionsMenu";
import "./styles/GameEntry.css";
import { Link } from "react-router-dom";

const GameEntry = ({ game }) => {
  const childUsername = useSelector((state) => state.user.childUsername);
  // eslint-disable-next-line 
  const [toggleOptionsMenu, setToggleOptionsMenu] = useState(false);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  const openMenu = () => {
    setToggleOptionsMenu((prevState) => {
      return { ...prevState };
    });
  };

  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setToggleOptionsMenu(false);
    }
  }
  document.addEventListener("mousedown", handleClickOutside);

  const selectGame = (game) => {
    dispatch(handleGameSlug(game.slug));
    dispatch(handleGame(game.name));
    // dispatch(selectLastSession(game.name));
  };

  return (
    <div className="GameEntry">
      <div className="game-icon">
        <Link
          to={{
            pathname: `/player/${childUsername}/${game.slug}`,
          }}
          onClick={() => selectGame(game)}
        >
          {/* <img src={`/images/${game.image}`} alt="Exermaze icon" /> */}
          <p>{game.name}</p>
        </Link>
      </div>
      <p className="game-hours">{game.hours}</p>
      <p className="game-highest-score">{game.highest_score}</p>
      <div className="games-options-container">
        <div className="games-options">
          {/* <FontAwesomeIcon icon={faEllipsisH} onClick={openMenu} /> */}
        </div>
        {/* <PlayerOptionsMenu wrapperRef={wrapperRef} isOpen={toggleOptionsMenu} /> */}
      </div>
    </div>
  );
};

export default GameEntry;
