import React from "react";
import { useSelector } from "react-redux";
import ProfileCard from "../components/ProfileCard";
import UserCard from "../components/UserCard";
import ViewHeader from "../components/ViewHeader";
import "./styles/Profile.css";

export default function Profile() {
  const username = useSelector((state) => state.user.username);

  return (
    <div className="Profile">
      <ViewHeader title="Profile" subtitle={`${username}'s profile`} />
      <div className="containers">
        <UserCard />
        <ProfileCard />
      </div>
    </div>
  );
}
