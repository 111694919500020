import React, { useState } from "react";
import "./styles/TermsAndConditions.css";

const TermsAndConditions = ({ toggle, closeModal, action }) => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 50 &&
      e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - 50;
    if (bottom) {
      setIsAtBottom(true);
    }
  };

  const acceptTermsAndConditions = () => {
    action();
  };

  return (
    <div
      className="TermsAndConditions"
      style={{ display: toggle ? "flex" : "none" }}
    >
      <div className="modal-window">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Terms and Conditions</h2>
            <div className="close-modal" onClick={closeModal}>
              <img src="/images/x.png" alt="X" />
            </div>
          </div>
          <div className="modal-body" onScroll={handleScroll}>
            <table width="95%">
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>
                        TR (Therapy Reloaded) SOFTWARE LICENSE AGREEMENT
                      </strong>
                    </p>
                    <p>
                      This Software License Agreement
                      (the&nbsp;&ldquo;Agreement&rdquo;) is between you and the
                      Ubitech entity that owns the Software that you are
                      accessing or using listed&nbsp;
                      <a href="https://ubitheraplay.com/">here</a>
                      &nbsp;(&ldquo;Ubitech&rdquo;&nbsp;or&nbsp;&ldquo;we&rdquo;).
                      If you are agreeing to this Agreement not as an individual
                      but on behalf of your company, government, or other entity
                      for which you are acting (for example, as an employee or
                      governmental official),
                      then&nbsp;&ldquo;you&rdquo;&nbsp;means your entity and you
                      are binding your entity to this Agreement. Ubitech may
                      modify this Agreement from time to time, subject to the
                      terms in Section 21 (Changes to this Agreement)
                      below.&nbsp; The Software is not intended for and should
                      not be used by anyone under the age of 16, if he/she are
                      not supervised by an authorised person.
                    </p>
                    <p>
                      The&nbsp;&ldquo;Effective Date&rdquo;&nbsp;of this
                      Agreement is the date which is the earlier of (a) your
                      initial access to or use of the Software (as defined
                      below) or (b) the effective date of the first Order
                      referencing this Agreement.
                    </p>
                    <p>
                      By clicking on the &ldquo;I agree&rdquo; that is presented
                      to you at the time of your Order, or by using or accessing
                      the Software, you indicate your assent to be bound by this
                      Agreement.&nbsp; If you do not agree to this Agreement, do
                      not use or access the Software.
                    </p>
                    <p>1. Scope of Agreement</p>
                    <p>
                      1.1.&nbsp;<u>Therapy Reloaded (TR) Software</u>. This
                      Agreement governs your initial purchase of Ubitech&rsquo;s
                      TR Software called Therapy Reloaded (or &ldquo;TR
                      Software&rdquo;), Support and Maintenance for the TR
                      Software, and any Additional Services, as well as any
                      future purchases made by you that reference this
                      Agreement.&nbsp; This Agreement includes each Order, the
                      Ubitech Policies, the Product-Specific Terms, and any
                      other referenced policies and terms.&nbsp; The TR
                      Software and its permitted use are further described in
                      the Documentation.&nbsp; The term &ldquo;TR
                      Software&rdquo; includes Documentation unless otherwise
                      specified.
                    </p>
                    <p>2. Accounts; Authorised Users</p>
                    <p>
                      2.1.&nbsp;<u>Account Registration</u>. You must register
                      for an account with us in order to access or receive TR
                      Software. Your registration information must be accurate,
                      current and complete. You must keep your registration
                      current so that we may send notices, statements and other
                      information to you by email or through your account. You
                      are responsible for all actions taken through your
                      account, including Orders made or Apps enabled (which may
                      incur fees).
                    </p>
                    <p>
                      2.2.&nbsp;<u>Authorised Users</u>. Only Authorised Users
                      may access and use the TR Software.&nbsp; Some TR
                      Software may allow you to designate different types of
                      Authorized Users, in which case pricing and functionality
                      may vary according to the type of Authorized User.&nbsp;
                      You are responsible for compliance with this Agreement by
                      all Authorised Users, including what Authorised Users do
                      with your data, and for all fees incurred by Authorised
                      Users (or from adding Authorised Users).&nbsp; All use of
                      TR Software must be solely for the benefit of you or your
                      Affiliates (except as expressly permitted in Section 2.3
                      below) and must be within the Scope of Use.
                    </p>
                    <p>
                      2.3.&nbsp;<u>Secondary Users</u>.&nbsp;As may be further
                      described in the Documentation, certain TR Software may
                      be used as part of your support (or similar) resources
                      related to your own products.&nbsp; Subject to the terms
                      and conditions of this Agreement, you may grant your own
                      end users (&ldquo;Secondary Users&rdquo;) limited rights
                      to use the TR Software solely so that they may view and
                      interact with such resources.&nbsp;
                    </p>
                    <p>3. Use of the TR Software</p>
                    <p>
                      3.1.&nbsp;<u>Your License Rights</u>.&nbsp;Subject to the
                      terms and conditions of this Agreement, Ubitech grants you
                      a non-exclusive, non-sublicensable and non-transferable
                      license to install and use the TR Software during the
                      applicable License Term for your own business purposes, in
                      accordance with this Agreement, your applicable Scope of
                      Use, the Documentation and all Laws.
                    </p>
                    <p>
                      3.2.&nbsp;<u>Restrictions</u>.&nbsp;Except as otherwise
                      expressly permitted in this Agreement, you will not: (a)
                      reproduce, modify, adapt or create derivative works of any
                      part of the TR Software; (b) rent, lease, distribute,
                      sell, sublicense, transfer, or provide access to the TR
                      Software to a third party; (c) use the TR Software for
                      the benefit of any third party; (d) incorporate the TR
                      Software into a product or service you provide to a third
                      party; (e) interfere with any license key mechanism in the
                      TR Software or otherwise circumvent mechanisms in the TR
                      Software intended to limit your use; (f) reverse engineer,
                      disassemble, decompile, translate, or otherwise seek to
                      obtain or derive the source code, underlying ideas,
                      algorithms, file formats or non-public APIs to the TR
                      Software, except to the extent expressly permitted by
                      applicable law (and then only upon advance notice to us);
                      (g) remove or obscure any proprietary or other notices
                      contained in the TR Software; (h) use the TR Software
                      for competitive analysis or to build competitive products;
                      (i) publicly disseminate information regarding the
                      performance of the TR Software; or (j) encourage or
                      assist any third party to do any of the foregoing.
                    </p>
                    <p>
                      3.3.&nbsp;<u>Number of Instances</u>.&nbsp;Unless
                      otherwise specified in your Order, for each TR Software
                      license that you purchase, you may install one (1)
                      production instance of the TR Software on systems owned
                      or operated by you or one of your Authorised Users. We may
                      also make available &ldquo;developer&rdquo; licenses free
                      of charge for certain of our TR Software offerings to
                      allow you to deploy non-production instances.
                    </p>
                    <p>
                      3.4.&nbsp;<u>Product-Specific Terms</u>.&nbsp;Some TR
                      Software may be subject to additional terms specific to
                      that TR Software as set forth in the Product-Specific
                      Terms.&nbsp; By accessing or using a product covered by
                      the Product-Specific Terms, you agree to the
                      Product-Specific Terms.
                    </p>
                    <p>
                      3.5.&nbsp;<u>Your Modifications</u>.&nbsp;Subject to the
                      terms and conditions of this Agreement (including&nbsp;
                      Section 2 (Combining the Products with Open Source
                      Software), for any elements of the TR Software provided
                      by Ubitech in source code form and to the extent permitted
                      in the Documentation, you may modify such source code
                      solely for purposes of developing bug fixes,
                      customizations and additional features for the TR
                      Software. Any modified source code or Documentation
                      constitutes&nbsp;&ldquo;Your Modifications&rdquo;. You may
                      use Your Modifications solely in support of your permitted
                      use of the TR Software (and only with your own instances
                      of the TR Software), but you may not distribute the code
                      to Your Modifications to any third party. Notwithstanding
                      anything in this Agreement to the contrary, Ubitech has no
                      support, warranty, indemnification or other obligation or
                      liability with respect to Your Modifications or their
                      combination, interaction or use with our TR Software.
                    </p>
                    <p>
                      3.6.&nbsp;<u>Attribution</u>.&nbsp;In any use of the TR
                      Software, you must not remove, obscure, or alter in any
                      way the following attribution to Ubitech on all user
                      interfaces to the TR Software: &ldquo;Powered by
                      Ubitech,&rdquo; which must in every case include a
                      hyperlink to http://www.ubitechit.com, and which must be
                      in the same format as delivered in the TR Software.
                    </p>
                    <p>
                      3.7.&nbsp;<u>System Requirements</u>.&nbsp;You are solely
                      responsible for ensuring that your systems meet the
                      hardware, Software and any other applicable system
                      requirements for the TR Software as specified in the
                      Documentation.&nbsp; Ubitech will have no obligations or
                      responsibility under this Agreement for issues caused by
                      your use of any third-party hardware or Software not
                      provided by Ubitech.
                    </p>
                    <p>
                      3.8.&nbsp;<u>Indemnification by You</u>.&nbsp;You will
                      defend, indemnify and hold harmless Ubitech from and
                      against any loss, cost, liability or damage (including
                      attorney&rsquo;s fees) arising from or relating to any
                      claim brought against Ubitech (a) arising from or related
                      to your breach of Section 2.3 (Secondary Users) or any
                      claims or disputes brought by Secondary Users arising out
                      of their use of the TR Software; (b) by a third party
                      relating to Your Modifications (including but not limited
                      to any representations or warranties you make about Your
                      Modifications of the TR Software) or your breach of
                      Section 3.5 (Your Modifications).
                    </p>
                    <p>
                      4. Privacy Terms.&nbsp;Ubitech may collect certain data
                      and information from you and Secondary Users in connection
                      with your and Secondary Users&rsquo; use of the TR
                      Software and otherwise in connection with this
                      Agreement.&nbsp; All such data and information will be
                      collected and used by Ubitech in accordance with
                      Ubitech&rsquo;s&nbsp;
                      <a href="https://www.atlassian.com/legal/privacy-policy">
                        Privacy Policy
                      </a>
                      , which you acknowledge.&nbsp; You may disable data
                      collection by the TR Software through the settings in the
                      TR Software.
                    </p>
                    <p>5. Ubitech Commitments</p>
                    <p>
                      5.1.&nbsp;<u>Support and Maintenance</u>. During the
                      period for which you have paid the applicable Support and
                      Maintenance fee, Ubitech will provide Support and
                      Maintenance for the TR Software in accordance with
                      the&nbsp;Ubitech Support Policy.&nbsp; Support and
                      Maintenance for TR Software includes access to New
                      Releases, if and when available, and any references to
                      &ldquo;TR Software&rdquo; in this Agreement include New
                      Releases.
                    </p>
                    <p>
                      5.2.&nbsp;<u>Additional Services</u>. Subject to this
                      Agreement, you may purchase Additional Services from
                      Ubitech, which Ubitech will provide to you pursuant to the
                      applicable Order.&nbsp; Additional Services may be subject
                      to additional policies and terms as specified by Ubitech.
                    </p>
                    <p>6. License Term, Returns and Payment</p>
                    <p>
                      6.1.&nbsp;<u>License Term and Renewals</u>.&nbsp;The
                      License Term, Support and Maintenance period, will be
                      indicated in the Order (as applicable).&nbsp; The License
                      Term and any applicable service periods will commence on
                      the Order date (unless a different start date is
                      designated in the Order) and expire on the expiration date
                      indicated in your account. Unless earlier terminated in
                      accordance with this Agreement, each right to use TR
                      Software will expire at the end of the applicable License
                      Term.&nbsp; Unless you have selected the
                      &ldquo;autorenewal&rdquo; option in your account, any
                      renewals must be mutually agreed upon by the parties in
                      writing. All renewals are subject to the applicable TR
                      Software or Support and Maintenance continuing to be
                      offered and will be charged at the then-current rates.
                    </p>
                    <p>
                      6.2.&nbsp;<u>Delivery</u>.&nbsp;We will deliver the
                      applicable license keys to your&nbsp;account no later than
                      when we have received payment of the applicable
                      fees.&nbsp; You are responsible for accessing your account
                      to determine that we have received payment and your Order
                      has been processed.&nbsp; All deliveries under this
                      Agreement will be electronic. For the avoidance of doubt,
                      you are responsible for installation of the TR Software,
                      and you acknowledge that Ubitech has no further delivery
                      obligation with respect to the TR Software after delivery
                      of the license keys.
                    </p>
                    <p>
                      6.3.&nbsp;<u>Return Policy</u>.&nbsp;As part of our
                      commitment to customer satisfaction, you may terminate
                      your initial Order of the applicable TR Software under
                      this Agreement, for no reason or any reason, by providing
                      notice of termination and returning any applicable TR
                      Software to Ubitech no later than thirty (30) days after
                      the Order date for such TR Software. In the event you
                      terminate your initial Order under this Section 6.3,
                      Ubitech may disable the license key that allowed the TR
                      Software to operate and, at your request (which may be
                      made through your account), Ubitech will refund you the
                      amount paid under such Order. This termination and refund
                      right applies only to your initial Order and only if you
                      exercise your termination right within the period
                      specified above, and does not apply to Additional
                      Services.&nbsp; You understand that Ubitech may change
                      this practice in the future in accordance with Section 21
                      (Changes to this Agreement).
                    </p>
                    <p>
                      6.4.&nbsp;<u>Increased Scope of Use</u>.&nbsp;During your
                      License Term, you may increase your Scope of Use (e.g.,
                      adding Authorised Users, licenses, copies or instances) by
                      placing a new Order or, if made available by Ubitech,
                      directly through the applicable TR Software.&nbsp; Any
                      increases to your Scope of Use will be subject to
                      additional fees, as set forth in the applicable Order.
                    </p>
                    <p>
                      6.5.&nbsp;<u>Payment</u>.&nbsp;You will pay all fees in
                      accordance with each Order, by the due dates and in the
                      currency specified in the Order.&nbsp; If a purchase order
                      number is required in order for an invoice to be paid,
                      then you must provide such purchase order number to
                      Ubitech by emailing the purchase order number to&nbsp;
                      <a href="mailto:management@ubitechit.com">
                        management@ubitechit.com
                      </a>
                      .&nbsp; Other than as expressly set forth in Section 6.3
                      (Return Policy) and Section 16 (IP Indemnification by
                      Ubitech), all amounts are non-refundable, non-cancelable
                      and non-creditable. You agree that we may bill your credit
                      card or other payment method for renewals, additional
                      users, expenses and unpaid fees, as applicable.
                    </p>
                    <p>
                      6.6.&nbsp;<u>Taxes</u>.&nbsp;Your fees under this
                      Agreement exclude any taxes or duties payable in respect
                      of the TR Software in the jurisdiction where the payment
                      is either made or received. To the extent that any such
                      taxes or duties are payable by Ubitech, you must pay to
                      Ubitech the amount of such taxes or duties in addition to
                      any fees owed under this Agreement. Notwithstanding the
                      foregoing, you may have obtained an exemption from
                      relevant taxes or duties as of the time such taxes or
                      duties are levied or assessed. In that case, you will have
                      the right to provide to Ubitech any such exemption
                      information, and Ubitech will use reasonable efforts to
                      provide such invoicing documents as may enable you to
                      obtain a refund or credit for the amount so paid from any
                      relevant revenue authority if such a refund or credit is
                      available.
                    </p>
                    <p>
                      6.7.&nbsp;<u>Withholding Taxes</u>.&nbsp;You will pay all
                      fees net of any applicable withholding taxes. You and
                      Ubitech will work together to avoid any withholding tax if
                      exemptions, or a reduced treaty withholding rate, are
                      available. If Ubitech qualifies for a tax exemption, or a
                      reduced treaty withholding rate, Ubitech will provide you
                      with reasonable documentary proof. You will provide
                      Ubitech reasonable evidence that you have paid the
                      relevant authority for the sum withheld or deducted.
                    </p>
                    <p>
                      6.9.&nbsp;<u>Future Functionality; Seperate Purchases</u>
                      .&nbsp;You acknowledge that the TR Software and
                      Additional Services referenced in an Order are being
                      purchased separately from any of our other products or
                      services. Payment obligations for any products or services
                      are not contingent on the purchase or use of any of our
                      other products (and for clarity, any purchases of TR
                      Software and Additional Services are separate and not
                      contingent on each other, even if listed on the same
                      Order).&nbsp; You agree that your purchases are not
                      contingent on the delivery of any future functionality or
                      features (including future availability of any TR
                      Software beyond the current License Term or any New
                      Releases), or dependent on any oral or written public
                      comments made by Ubitech regarding future functionality or
                      features.
                    </p>
                    <p>
                      7. No-Charge Software.&nbsp;We may offer certain TR
                      Software (including some Ubitech Apps) to you at no
                      charge, including free accounts, trial use, and Beta
                      Versions as defined below
                      (collectively,&nbsp;&ldquo;No-Charge TR Software&rdquo;).
                      Your use of No-Charge TR Software is subject to any
                      additional terms that we specify and is only permitted
                      during the License Term we designate (or, if not
                      designated, until terminated in accordance with this
                      Agreement).
                    </p>
                    <p>
                      8. Ownership and Feedback.&nbsp;The TR Software is made
                      available on a limited license or access basis, and no
                      ownership right is conveyed to you, irrespective of the
                      use of terms such as &ldquo;purchase&rdquo; or
                      &ldquo;sale&rdquo;. Ubitech and its licensors have and
                      retain all right, title and interest, including all
                      intellectual property rights, in and to Ubitech Technology
                      (including the TR Software).&nbsp; From time to time, you
                      may choose to submit Feedback to us. Ubitech may in
                      connection with any of its products or services freely
                      use, copy, disclose, license, distribute and exploit any
                      Feedback in any manner without any obligation, royalty or
                      restriction based on intellectual property rights or
                      otherwise. No Feedback will be considered your
                      Confidential Information, and nothing in this Agreement
                      limits Ubitech's right to independently use, develop,
                      evaluate, or market products or services, whether
                      incorporating Feedback or otherwise.
                    </p>
                    <p>
                      9. Confidentiality.&nbsp;Except as otherwise set forth in
                      this Agreement, each party agrees that all code,
                      inventions, know-how and business, technical and financial
                      information disclosed to such party (&ldquo;Receiving
                      Party&rdquo;) by the disclosing party (&ldquo;Disclosing
                      Party&rdquo;) constitute the confidential property of the
                      Disclosing Party (&ldquo;Confidential Information&rdquo;),
                      provided that it is identified as confidential at the time
                      of disclosure or should be reasonably known by the
                      Receiving Party to be confidential or proprietary due to
                      the nature of the information disclosed and the
                      circumstances surrounding the disclosure. Any Ubitech
                      Technology and any performance information relating to the
                      TR Software will be deemed Confidential Information of
                      Ubitech without any marking or further designation. Except
                      as expressly authorised herein, the Receiving Party will
                      (1) hold in confidence and not disclose any Confidential
                      Information to third parties and (2) not use Confidential
                      Information for any purpose other than fulfilling its
                      obligations and exercising its rights under this
                      Agreement.&nbsp; The Receiving Party may disclose
                      Confidential Information to its employees, agents,
                      contractors and other representatives having a legitimate
                      need to know, provided that they are bound to
                      confidentiality obligations no less protective of the
                      Disclosing Party than this Section 9 and that the
                      Receiving Party remains responsible for compliance by them
                      with the terms of this Section 9. The Receiving Party's
                      confidentiality obligations will not apply to information
                      which the Receiving Party can document: (i) was rightfully
                      in its possession or known to it prior to receipt of the
                      Confidential Information; (ii) is or has become public
                      knowledge through no fault of the Receiving Party; (iii)
                      is rightfully obtained by the Receiving Party from a third
                      party without breach of any confidentiality obligation; or
                      (iv) is independently developed by employees of the
                      Receiving Party who had no access to such information. The
                      Receiving Party may also disclose Confidential Information
                      if so required pursuant to a regulation, law or court
                      order (but only to the minimum extent required to comply
                      with such regulation or order and with advance notice to
                      the Disclosing Party). The Receiving Party acknowledges
                      that disclosure of Confidential Information would cause
                      substantial harm for which damages alone would not be a
                      sufficient remedy, and therefore that upon any such
                      disclosure by the Receiving Party the Disclosing Party
                      will be entitled to appropriate equitable relief in
                      addition to whatever other remedies it might have at law.
                    </p>
                    <p>10. Term and Termination</p>
                    <p>
                      10.1.&nbsp;<u>Term</u>.&nbsp;This Agreement is effective
                      as of the Effective Date and continues until expiration of
                      all License Terms, unless earlier terminated as set forth
                      herein.
                    </p>
                    <p>
                      10.2.&nbsp;<u>Termination for Cause</u>. Either party may
                      terminate this Agreement (including all related Orders) if
                      the other party (a) fails to cure any material breach of
                      this Agreement within thirty (30) days after written
                      notice; (b) ceases operation without a successor; or (c)
                      seeks protection under any bankruptcy, receivership, trust
                      deed, creditors&rsquo; arrangement, composition or
                      comparable proceeding, or if any such proceeding is
                      instituted against that party (and not dismissed within
                      sixty (60) days thereafter).&nbsp;
                    </p>
                    <p>
                      10.3.&nbsp;<u>Termination for Convenience</u>.&nbsp;You
                      may choose to stop using the TR Software and terminate
                      this Agreement at any time for any reason upon written
                      notice to Ubitech, but, unless you are exercising your
                      right to terminate early pursuant to Section 6.3 (Return
                      Policy), upon any such termination (i) you will not be
                      entitled to a refund of any pre-paid fees and (ii) if you
                      have not already paid all applicable fees for the
                      then-current License Term or related services period (as
                      applicable), any such fees that are outstanding will
                      become immediately due and payable.
                    </p>
                    <p>
                      10.4.&nbsp;<u>Effects of Termination</u>.&nbsp;Upon any
                      expiration or termination of this Agreement, your license
                      to the TR Software terminates and you must cease using
                      and delete (or at our request, return) all TR Software
                      and Confidential Information or other materials of Ubitech
                      in your possession, including on any third-party systems
                      operated on your behalf.&nbsp; You will certify such
                      deletion upon our request. If this Agreement is terminated
                      by you in accordance with Section 10.2 (Termination for
                      Cause), Ubitech will refund you any prepaid TR Software
                      fees covering the remainder of the then-current License
                      Term after the effective date of termination. If this
                      Agreement is terminated by Ubitech in accordance with
                      Section 10.2 (Termination for Cause), you will pay any
                      unpaid fees covering the remainder of the then-current
                      License Term after the effective date of termination. In
                      no event will termination relieve you of its obligation to
                      pay any fees payable to Ubitech for the period prior to
                      the effective date of termination. Except where an
                      exclusive remedy may be specified in this Agreement, the
                      exercise by either party of any remedy, including
                      termination, will be without prejudice to any other
                      remedies it may have under this Agreement, by law, or
                      otherwise.
                    </p>
                    <p>11. Warranties and Disclaimer</p>
                    <p>
                      11.1.&nbsp;<u>General Warranties</u>.&nbsp;Each party
                      represents and warrants that it has the legal power and
                      authority to enter into this Agreement.&nbsp; If you are
                      an entity, you represent and warrant that this Agreement
                      and each Order is entered into by an employee or agent of
                      such entity with all necessary authority to bind such
                      entity to the terms and conditions of this Agreement.
                    </p>
                    <p>
                      11.2.&nbsp;<u>Virus Warranty</u>.&nbsp;Ubitech further
                      represents and warrants that it will take reasonable
                      commercial efforts to ensure that the TR Software, in the
                      form and when provided to you, will be free of any
                      viruses, malware, or other harmful code.&nbsp; For any
                      breach of the foregoing warranty, your sole and exclusive
                      remedy, and Ubitech&rsquo;s sole obligation, is to provide
                      a replacement copy of the TR Software promptly upon
                      notice.
                    </p>
                    <p>
                      11.3.&nbsp;<u>Warranty Disclaimer</u>.&nbsp;EXCEPT AS
                      EXPRESSLY SET FORTH IN SECTION 11.1 (GENERAL WARRANTIES)
                      AND 11.2 (VIRUS WARRANTY), ALL TR SOFTWARE, SUPPORT AND
                      MAINTENANCE AND ANY ADDITIONAL SERVICES ARE PROVIDED
                      &ldquo;AS IS,&rdquo; AND UBITECH AND ITS SUPPLIERS
                      EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND
                      REPRESENTATIONS OF ANY KIND, INCLUDING ANY WARRANTY OF
                      NON-INFRINGEMENT, TITLE, FITNESS FOR A PARTICULAR PURPOSE,
                      FUNCTIONALITY, OR MERCHANTABILITY, WHETHER EXPRESS,
                      IMPLIED, OR STATUTORY. UBITECH WILL NOT BE LIABLE FOR
                      DELAYS, INTERRUPTIONS, SERVICE FAILURES OR OTHER PROBLEMS
                      INHERENT IN USE OF THE INTERNET AND ELECTRONIC
                      COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE THE REASONABLE
                      CONTROL OF UBITECH. TO THE MAXIMUM EXTENT PERMITTED BY
                      LAW, NEITHER UBITECH MAKES ANY REPRESENTATION, WARRANTY OR
                      GUARANTEE AS TO THE RELIABILITY, TIMELINESS, QUALITY,
                      SUITABILITY, TRUTH, AVAILABILITY, ACCURACY OR COMPLETENESS
                      OF ANY &nbsp;SOFTWARE OR ANY CONTENT THEREIN OR GENERATED
                      THEREWITH, OR THAT: (A) THE USE OF ANY SOFTWARE WILL BE
                      SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE; (B) THE TR
                      SOFTWARE WILL OPERATE IN COMBINATION WITH ANY OTHER
                      HARDWARE, SOFTWARE, SYSTEM, OR DATA; (C) THE TR SOFTWARE
                      (OR ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
                      PURCHASED OR OBTAINED BY YOU THROUGH THE TR SOFTWARE)
                      WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS); (D) ERRORS
                      OR DEFECTS WILL BE CORRECTED; OR (E) EXCEPT AS EXPRESSLY
                      SET FORTH IN SECTION 11.2 (VIRUS WARRANTY), THE TR
                      SOFTWARE IS FREE OF VIRUSES OR OTHER HARMFUL
                      COMPONENTS.&nbsp; YOU MAY HAVE OTHER STATUTORY RIGHTS, BUT
                      THE DURATION OF STATUTORILY REQUIRED WARRANTIES, IF ANY,
                      WILL BE LIMITED TO THE SHORTEST PERIOD PERMITTED BY LAW.
                    </p>
                    <p>12. Limitations of Liability</p>
                    <p>
                      15.1.&nbsp;<u>Consequential Damages Waiver</u>. EXCEPT FOR
                      EXCLUDED CLAIMS (DEFINED BELOW), NEITHER PARTY WILL HAVE
                      ANY LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT
                      FOR ANY LOSS OF USE, LOST OR INACCURATE DATA, LOST
                      PROFITS, FAILURE OF SECURITY MECHANISMS, INTERRUPTION OF
                      BUSINESS, COSTS OF DELAY OR ANY INDIRECT, SPECIAL,
                      INCIDENTAL, RELIANCE OR CONSEQUENTIAL DAMAGES OF ANY KIND,
                      EVEN IF INFORMED OF THE POSSIBILITY OF SUCH DAMAGES IN
                      ADVANCE.
                    </p>
                    <p>
                      12.2.&nbsp;<u>Excluded Claims</u>.&nbsp;&ldquo;Excluded
                      Claims&rdquo;&nbsp;means (1) amounts owed by you under any
                      Orders, (2) either party&rsquo;s express indemnification
                      obligations in this Agreement, and (3) your breach of
                      Section 3.2 (Restrictions) or of Section 2 (Combining the
                      Products with Open Source Software).
                    </p>
                    <p>
                      13. IP Indemnification by Ubitech.&nbsp;We will defend you
                      against any claim brought against you by a third party
                      alleging that the TR Software, when used as authorised
                      under this Agreement, infringes any third-party patent,
                      copyright or trademark, or misappropriates any third-party
                      trade secret enforceable in any jurisdiction that is a
                      signatory to the Berne Convention (a &ldquo;Claim&rdquo;),
                      and we will indemnify you and hold you harmless against
                      any damages and costs finally awarded on the Claim by a
                      court of competent jurisdiction or agreed to via
                      settlement executed by Ubitech (including reasonable
                      attorneys&rsquo; fees), provided that we have received
                      from you: (a) prompt written notice of the Claim (but in
                      any event notice in sufficient time for us to respond
                      without prejudice); (b) reasonable assistance in the
                      defense and investigation of the Claim, including
                      providing us a copy of the Claim, all relevant evidence in
                      your possession, custody, or control, and cooperation with
                      evidentiary discovery, litigation, and trial, including
                      making witnesses within your employ or control available
                      for testimony; and (c) the exclusive right to control and
                      direct the investigation, defense, and settlement (if
                      applicable) of the Claim. If your use of the TR Software
                      is (or in our opinion is likely to be) enjoined, whether
                      by court order or by settlement, or if we determine such
                      actions are reasonably necessary to avoid material
                      liability, we may, at our option and in our discretion:
                      (i) procure the right or license for your continued use of
                      the TR Software in accordance with this Agreement; (ii)
                      substitute substantially functionally similar TR
                      Software; or (iii) terminate your right to continue using
                      the TR Software and refund any license fees pre-paid by
                      you for use of the TR Software for the terminated portion
                      of the applicable License Term or, in the case of any
                      &ldquo;perpetual&rdquo; licenses, the license fee paid by
                      you as reduced to reflect a three (3) year straight-line
                      depreciation from the license purchase date.
                      Ubitech&rsquo;s indemnification obligations above do not
                      apply: (1) if the total aggregate fees received by Ubitech
                      with respect to your license to TR Software in the twelve
                      (12) month period immediately preceding the Claim is less
                      than UK &pound;50,000; (2) if the TR Software is modified
                      by any party other than Ubitech, but solely to the extent
                      the alleged infringement is caused by such modification;
                      (3) if the TR Software is used in combination with any
                      non-Ubitech product, Software, service or equipment, but
                      solely to the extent the alleged infringement is caused by
                      such combination; (4) to unauthorised use of TR Software;
                      (5) to any Claim arising as a result of (y) circumstances
                      covered by your indemnification obligations in Section 3.8
                      (Indemnification by You) or (z) any third-party
                      deliverables or components contained with the TR
                      Software; (6) to any unsupported release of the TR
                      Software; or (7) if you settle or make any admissions with
                      respect to a Claim without Ubitech&rsquo;s prior written
                      consent. THIS SECTION 13 STATES OUR SOLE LIABILITY AND
                      YOUR EXCLUSIVE REMEDY FOR ANY INFRINGEMENT OF INTELLECTUAL
                      PROPERTY RIGHTS IN CONNECTION WITH ANY TR SOFTWARE OR
                      OTHER ITEMS PROVIDED BY UBITECH UNDER THIS AGREEMENT.
                    </p>
                    <p>
                      14. Publicity Rights.&nbsp;We may identify you as an
                      Ubitech customer in our promotional materials. We will
                      promptly stop doing so upon your request sent to&nbsp;
                      <a href="mailto:management@ubitechit.com">
                        management@ubitechit.com
                      </a>
                      .
                    </p>
                    <p>15. Dispute Resolution</p>
                    <p>
                      15.1.&nbsp;<u>Informal Resolution</u>. In the event of any
                      controversy or claim arising out of or relating to this
                      Agreement, the parties will consult and negotiate with
                      each other and, recognising their mutual interests,
                      attempt to reach a solution satisfactory to both parties.
                      If the parties do not reach settlement within a period of
                      sixty (60) days, either party may pursue relief as may be
                      available under this Agreement pursuant to Section 15.2
                      (Governing Law; Jurisdiction). All negotiations pursuant
                      to this Section 15.1 will be confidential and treated as
                      compromise and settlement negotiations for purposes of all
                      rules and codes of evidence of applicable legislation and
                      jurisdictions.
                    </p>
                    <p>
                      15.2.&nbsp;<u>Governing Law; Jurisdiction</u>. This
                      Agreement will be governed by and construed in accordance
                      with the applicable laws of the UK.
                    </p>
                    <p>
                      15.3.&nbsp;<u>Injunctive Relief; Enforcement</u>
                      .&nbsp;Notwithstanding the provisions of Section 15.1
                      (Informal Resolution) and Section 15.2 (Governing Law;
                      Jurisdiction), nothing in this Agreement will prevent
                      Ubitech from seeking injunctive relief with respect to a
                      violation of intellectual property rights, confidentiality
                      obligations or enforcement or recognition of any award or
                      order in any appropriate jurisdiction.
                    </p>
                    <p>
                      15.4.&nbsp;<u>Exclusion of UN Convention and UCITA</u>
                      .&nbsp;The terms of the United Nations Convention on
                      Contracts for the Sale of Goods do not apply to this
                      Agreement. The Uniform Computer Information Transactions
                      Act (UCITA) will not apply to this Agreement regardless of
                      when or where adopted.
                    </p>
                    <p>
                      16. Export Restrictions.&nbsp;The TR Software is subject
                      to export restrictions by the United Kingdom government
                      and may be subject to import restrictions by certain
                      foreign governments, and you agree to comply with all
                      applicable export and import laws and regulations in your
                      download of, access to, and use of the TR Software.&nbsp;
                    </p>
                    <p>17.&nbsp;Changes to this Agreement.</p>
                    <p>
                      17.1.&nbsp;<u>Modifications Generally</u>. We may modify
                      the terms and conditions of this Agreement (including
                      Ubitech Policies) from time to time, with notice given to
                      you by email, through the TR Software or through our
                      website.&nbsp; Together with notice, we will specify the
                      effective date of the modifications.
                    </p>
                    <p>
                      <u>Paid Licenses</u>:&nbsp; Typically, when we make
                      modifications to the main body of this Agreement
                      (excluding the Ubitech Policies), the modifications will
                      take effect at the next renewal of your License Term and
                      will automatically apply as of the renewal date unless you
                      elect not to renew pursuant to Section 6.1 (License Term
                      and Renewals). In some cases &ndash; e.g., to address
                      compliance with Laws, or as necessary for new features
                      &ndash; we may specify that such modifications become
                      effective during your then-current License Term. If the
                      effective date of such modifications is during your
                      then-current License Term and you object to the
                      modifications, then (as your exclusive remedy) you may
                      terminate your affected Orders upon notice to us, and we
                      will refund to you any fees you have pre-paid for use of
                      the affected TR Software for the terminated portion of
                      the applicable License Term. To exercise this right, you
                      must provide us with notice of your objection and
                      termination within thirty (30) days of us providing notice
                      of the modifications.&nbsp; For the avoidance of doubt,
                      any Order is subject to the version of this Agreement in
                      effect at the time of the Order.
                    </p>
                    <p>
                      <u>Ubitech Policies</u>:&nbsp; Our products and business
                      are constantly evolving, and we may modify the Ubitech
                      Policies from time to time, including during your
                      then-current License Term in order to respond to changes
                      in our products, our business, or Laws. In this case,
                      unless required by Laws, we agree not to make
                      modifications to the Ubitech Policies that, considered as
                      a whole, would substantially diminish our obligations
                      during your then-current License Term. Modifications to
                      the Ubitech Policies will take effect automatically as of
                      the effective date specified for the updated policies.
                    </p>
                    <p>18. General Provisions</p>
                    <p>
                      18.1.&nbsp;<u>Notices</u>.&nbsp;Any notice under this
                      Agreement must be given in writing. We may provide notice
                      to you via email or through your account. You agree that
                      any such electronic communication will satisfy any
                      applicable legal communication requirements, including
                      that such communications be in writing. Our notices to you
                      will be deemed given upon the first business day after we
                      send it. You will provide notice to us by post to Ubitech
                      Ltd. Your notices to us will be deemed given upon our
                      receipt.
                    </p>
                    <p>
                      18.2.&nbsp;<u>Force Majeure</u>.&nbsp;Neither party will
                      be liable to the other for any delay or failure to perform
                      any obligation under this Agreement (except for a failure
                      to pay fees) if the delay or failure is due to events
                      which are beyond the reasonable control of such party,
                      such as a strike, blockade, war, act of terrorism, riot,
                      natural disaster, failure or diminishment of power or
                      telecommunications or data networks or services, or
                      refusal of a license by a government agency.
                    </p>
                    <p>
                      18.3.&nbsp;<u>Assignment</u>.&nbsp;You may not assign or
                      transfer this Agreement without our prior written consent.
                      As an exception to the foregoing, you may assign this
                      Agreement in its entirety (including all Orders) to your
                      successor resulting from your merger, acquisition, or sale
                      of all or substantially all of your assets or voting
                      securities, provided that you provide us with prompt
                      written notice of the assignment and the assignee agrees
                      in writing to assume all of your obligations under this
                      Agreement. Any attempt by you to transfer or assign this
                      Agreement except as expressly authorized above will be
                      null and void. We may assign our rights and obligations
                      under this Agreement (in whole or in part) without your
                      consent. We may also permit our Affiliates, agents and
                      contractors to exercise our rights or perform our
                      obligations under this Agreement, in which case we will
                      remain responsible for their compliance with this
                      Agreement.&nbsp; Subject to the foregoing, this Agreement
                      will inure to the parties&rsquo; permitted successors and
                      assigns.
                    </p>
                    <p>
                      18.4.&nbsp;<u>Entire Agreement</u>.&nbsp;This Agreement is
                      the entire agreement between you and Ubitech relating to
                      the TR Software and supersedes all prior or
                      contemporaneous oral or written communications, proposals
                      and representations between you and Ubitech with respect
                      to the TR Software or any other subject matter covered by
                      this Agreement.&nbsp; No provision of any purchase order
                      or other business form employed by you will supersede or
                      supplement the terms and conditions of this Agreement, and
                      any such document relating to this Agreement will be for
                      administrative purposes only and will have no legal
                      effect.
                    </p>
                    <p>
                      19. Definitions.&nbsp;Certain capitalized terms are
                      defined in this Section 19, and others are defined
                      contextually in this Agreement.
                    </p>
                    <p>
                      &ldquo;Affiliate&rdquo;&nbsp;means an entity which,
                      directly or indirectly, owns or controls, is owned or is
                      controlled by or is under common ownership or control with
                      a party, where &ldquo;control&rdquo; means the power to
                      direct the management or affairs of an entity, and
                      &ldquo;ownership&rdquo; means the beneficial ownership of
                      greater than 50% of the voting equity securities or other
                      equivalent voting interests of the entity.
                    </p>
                    <p>
                      &ldquo;Ubitech Technology&rdquo;&nbsp;means the TR
                      Software, Ubitech Deliverables, their &ldquo;look and
                      feel&rdquo;, any and all related or underlying technology
                      and any modifications or derivative works of the
                      foregoing, including as they may incorporate Feedback.
                    </p>
                    <p>
                      &ldquo;Authorised Users&rdquo;&nbsp;means the specific
                      individuals whom you designate to use the applicable TR
                      Software and for whom you have paid the required
                      fees.&nbsp; Authorised Users may be your or your
                      Affiliates&rsquo; employees, representatives, consultants,
                      contractors, agents or other third parties who are acting
                      for your or your Affiliates&rsquo; benefit or on your or
                      your Affiliates&rsquo; behalf.&nbsp; Authorised Users also
                      include any Secondary Users that you permit to use the TR
                      Software, subject to Section 2.3 (Secondary Users).
                    </p>
                    <p>
                      &ldquo;Documentation&rdquo;&nbsp;means our standard
                      published documentation for the TR Software, currently
                      located&nbsp;<a href="https://ubitheraplay.com/">here</a>.
                    </p>
                    <p>
                      &ldquo;Feedback&rdquo;&nbsp;means comments, questions,
                      ideas, suggestions or other feedback relating to the TR
                      Software, Support and Maintenance or Additional Services.
                    </p>
                    <p>
                      &ldquo;Laws&rdquo;&nbsp;means all applicable local, state
                      and international laws, regulations and conventions,
                      including those related to data privacy and data transfer,
                      international communications and the exportation of
                      technical or personal data.
                    </p>
                    <p>
                      &ldquo;License Term&rdquo;&nbsp;means your permitted
                      license term for the TR Software, as set forth in an
                      Order.
                    </p>
                    <p>
                      &ldquo;New Releases&rdquo;&nbsp;means any bug fixes,
                      patches, major or minor releases, or any other changes,
                      enhancements, or modifications to the TR Software that
                      Ubitech makes available to you as part of Support and
                      Maintenance.
                    </p>
                    <p>
                      &ldquo;Order&rdquo;&nbsp;means Ubitech&rsquo;s applicable
                      ordering documentation or other purchase flow referencing
                      this Agreement.&nbsp; Orders may include purchases of TR
                      Software licenses, Support and Maintenance, Additional
                      Services, increased or upgraded Scope of Use or renewals.
                    </p>
                    <p>
                      &ldquo;Scope of Use&rdquo;&nbsp;means your authorised
                      scope of use for the TR Software as specified in the
                      applicable Order, which may include: (a) number and type
                      of Authorised Users, (b) numbers of licenses, copies or
                      instances, or (c) entity, division, business unit,
                      website, field of use or other restrictions or billable
                      units.
                    </p>
                    <p>
                      &ldquo;TR Software&rdquo;&nbsp;means Ubitech&rsquo;s
                      commercially available downloadable TR Software products,
                      including mobile applications of such products.&nbsp; Your
                      Order will specify the TR Software that you may use.
                    </p>
                    <p>
                      &ldquo;Support and Maintenance&rdquo;&nbsp;means
                      Ubitech&rsquo;s support and maintenance services for the
                      TR Software.
                    </p>
                    <p>
                      &ldquo;Training&rdquo;&nbsp;means Ubitech-provided
                      training and certification services.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer">
          <button id="cancel-btn" onClick={closeModal}>
            Cancel
          </button>
          <button
            className={`${isAtBottom ? "hasScrolled" : ""}`}
            disabled={isAtBottom ? false : true}
            onClick={acceptTermsAndConditions}
          >
            I Agree
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
