import { createSlice } from "@reduxjs/toolkit";
import { Constants } from "../services/urls";
import processGraphData from "../utils/graphData";
import moment from "moment";
import axios from "axios";

const { URL } = Constants;

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: localStorage.getItem("username"),
    email: "",
    password: "",
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    userType: localStorage.getItem("userType"),
    hasAcceptedTerms: JSON.parse(localStorage.getItem("hasAcceptedTerms")),
    children: [],
    childId: JSON.parse(localStorage.getItem("childId")),
    childUsername: "",
    sessions: [],
    session: JSON.parse(localStorage.getItem("sessionId")),
    sessionScore: 0,
    sessionFound: true,
    sessionMinutesPlayed: 0,
    allSessions: [],
    levels: [],
    level: {},
    subscriptionExpired: false,
    // sessionTitle: "",
    graphData: [],
    chartObj: {
      labels: [],
      data: [],
    },
    chartMonths: {},
    setupFiles: [],
    gamesData: [],
    game: "",
    gameSlug: "",
    serverMsg: "",
    isLogged: JSON.parse(localStorage.getItem("isLogged")),
    counter: 0,
    childOptions: {
      errorless: 0,
      colourInvert: 0,
      lowQuality: 0,
      scale: 1,
    },
    showOutliers: false,
    showCentering: false,
  },
  reducers: {
    loadChildren: (state, action) => {
      state.children = [...action.payload];
    },
    handleUsername: (state, action) => {
      state.username = action.payload;
    },
    handleEmail: (state, action) => {
      state.email = action.payload;
    },
    handlePassword: (state, action) => {
      state.password = action.payload;
    },
    handleChildUsername: (state, action) => {
      state.childUsername = action.payload;
    },
    handleChildId: (state, action) => {
      state.childId = action.payload;
      localStorage.setItem("childId", action.payload);
    },
    handleGameData: (state, action) => {
      state.game = action.payload;
    },
    handleGameSlug: (state, action) => {
      state.gameSlug = action.payload;
    },
    login: (state, action) => {
      state.username = action.payload.Username;
      state.userType = action.payload.Type;
      state.hasAcceptedTerms = action.payload.HasAcceptedTerms;
      state.isLogged = true;
      state.userInfo["createdAt"] = action.payload.CreatedAt;
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
      localStorage.setItem("username", action.payload.Username);
      localStorage.setItem("userType", action.payload.Type);
      localStorage.setItem("isLogged", true);
      localStorage.setItem("jwt", action.payload.Token);
      localStorage.setItem("hasAcceptedTerms", action.payload.HasAcceptedTerms);
    },
    logout: (state) => {
      state.isLogged = false;
      localStorage.setItem("username", "");
      localStorage.setItem("userType", "");
      localStorage.setItem("isLogged", false);
      localStorage.setItem("jwt", "");
    },
    setSubscriptionExpired: (state, action) => {
      state.subscriptionExpired = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo.subscription = { ...action.payload };
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    loadGraphData: (state, action) => {
      state.cleanGraphData = action.payload;
      state.graphData = [
        ...processGraphData(
          action.payload,
          state.showOutliers,
          state.showCentering        ),
      ];
      state.sessionFound = state.graphData.length > 0;
    },
    setSessions: (state, action) => {
      state.sessions = action.payload;
    },
    setLevels: (state, action) => {
      state.levels = action.payload;
    },
    handleLevel: (state, action) => {
      state.level = action.payload;
    },
    handleSessionScore: (state, action) => {
      state.sessionScore = action.payload === null ? "N/A" : action.payload;
    },
    handleSessionId: (state, action) => {
      state.session = action.payload;
      state.sessionFound = state.session > -1 ? true : false;
      localStorage.setItem("sessionId", action.payload);
      //TODO: handleSession to replace store, id and title
      // state.session = {...state.session, [action.payload]: action.payload}
    },
    handleSessionTitle: (state, action) => {
      state.game = action.payload;
    },
    selectLastSession: (state, action) => {
      let lastSession = state.sessions.find(
        (session) => session.Title === action.payload
      );
      state.session = lastSession ? lastSession.Id : -1;
      state.sessionScore = lastSession ? lastSession.Result : 0;
      state.sessionFound = state.session > -1 ? true : false;
    },
    setAllSessions: (state, action) => {
      let childrenIds = state.children.map((child) => child.Id);

      //find all children's sessions
      let allSessions = [];
      let hoursPlayed = {};
      action.payload.map((session) => {
        if (childrenIds.includes(session.userId) && session.end !== null) {
          //calculate hours played
          if (hoursPlayed[session.userId]) {
            hoursPlayed[session.userId] += addMinutesPlayed(
              session.start,
              session.end
            );
          } else {
            hoursPlayed[session.userId] = addHoursPlayed(
              session.start,
              session.end
            );
          }
          allSessions.push({
            ...session,
            ...state.children.find((child) => child.Id === session.userId),
            hoursPlayed: hoursPlayed[session.userId],
          });
        }
      });
      state.allSessions = allSessions;

      //calculate total hours played by month
      // TODO: Evaluate how the moths are accumalated, look at what the truth is and compare.
      // state.chartMonths["June"] = 0;
      // state.chartMonths["July"] = 0;
      // state.chartMonths["August"] = 0;
      // state.chartMonths["September"] = 0;
      // state.chartMonths["October"] = 0;
      // state.chartMonths["November"] = 0;
      // state.chartMonths["December"] = 0; 
      // state.chartMonths["January"] = 0;
      // state.chartMonths["February"] = 0;
      // state.chartMonths["March"] = 0;
      // state.chartMonths["April"] = 0;
      // state.chartMonths["May"] = 0;
      const startingIndex = new Date().getMonth() + 1;
      let currentIndex = startingIndex;
      let monthsofYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      monthsofYear.forEach((month, index) => {
          if(index >= startingIndex) {
            state.chartMonths[month] = 0;
          }
      });
      monthsofYear.forEach((month, index) => {
        if(index < startingIndex) {
          state.chartMonths[month] = 0;
        }
    });
      //check for last 12 months only, then do the initalisation for each month and get the current month (current month is at the end)
      //Add 1 to the current month, iterate through - create dummy array with months of the year, if we get to 12 (set back to 0)
      //if index = starting index which is the starting month then end 
      allSessions.map((session) => {
        let check = moment(session.start);
        let month = check.format("MMMM");
        // if (!(month in state.chartMonths)) {
        //   state.chartMonths[month] = 0;
        // } 
        state.chartMonths[month] += addMinutesPlayed(session.start, session.end);
      });

      //get the most recent session only
      let lastSessions = allSessions.filter(
        (sess, index, self) => self.findIndex((t) => t.Id === sess.Id) === index
      );
      let lastSessionsIds = lastSessions.map((sess) => sess.Id);

      //add most recent session to children
      let childrenLastSess = [];
      state.children.map((child) => {
        if (lastSessionsIds.includes(child.Id)) {
          childrenLastSess.push({
            ...child,
            lastSession: lastSessions.find((sess) => sess.Id === child.Id)
              .start,
            totalMinutes: hoursPlayed[child.Id].toFixed(2),
          });
        } else {
          childrenLastSess.push({
            ...child,
            lastSession: "",
            totalMinutes: 0,
          });
        }
      });
      //sort them by most recent played
      childrenLastSess.sort((a, b) =>
        a.lastSession < b.lastSession
          ? 1
          : b.lastSession < a.lastSession
          ? -1
          : 0
      );
      state.children = childrenLastSess;
    },
    cleanAuthFields: (state) => {
      state.username = "";
      state.email = "";
      state.password = "";
      state.serverMsg = "";
    },
    cleanChildUsername: (state) => {
      state.childUsername = "";
    },
    setServerMsg: (state, action) => {
      state.serverMsg = action.payload;
    },
    handleChildOptions: (state, action) => {
      state.childOptions = {
        errorless: action.payload.IsErrorless,
        colourInvert: action.payload.IsColourInvert,
        lowQuality: action.payload.IsLowQuality,
        scale: action.payload.Scale,
      };
    },
    handleGame: (state, action) => {
      state.game = action.payload;
    },
    handleGamesData: (state, action) => {
      state.gamesData = [...action.payload];
      //Games: [Description, Files:[FileId, ExePath, Url, Version], GameId, Media:[], Title]
      // flattern Files array to show the [0] element //TODO: check if this is the latest file to show
      for (let i = 0; i < state.gamesData.length; i++) {
        let files = state.gamesData[i].Files;
        state.gamesData[i].Files = {};
        state.gamesData[i].Files.FileId = files[0].FileId;
        state.gamesData[i].Files.Url = files[0].Url;
        state.gamesData[i].Files.ExePath = files[0].ExePath;
        state.gamesData[i].Files.Version = files[0].Version;
      }
    },
    handleSetupFilesData: (state, action) => {
      state.setupFiles = [...action.payload];
    },
    updateGameData: (state, action) => {
      console.log("update single game");
    },
    setShowOutliers: (state, action) => {
      state.showOutliers = action.payload;
      state.graphData = [
        ...processGraphData(
          state.cleanGraphData,
          state.showOutliers,
          state.showCentering
        ),
      ];
    },
    setShowCentering: (state, action) => {
      state.showCentering = action.payload;
      state.graphData = [
        ...processGraphData(
          state.cleanGraphData,
          state.showOutliers,
          state.showCentering
        ),
      ];
    },
    setTermsAndConditions: (state) => {
      state.hasAcceptedTerms = 1;
      localStorage.setItem("hasAcceptedTerms", 1);
    },
  },
});

const addHoursPlayed = (sessStart, sessEnd) => {
  var start = moment(new Date(sessStart));
  var end = moment(new Date(sessEnd));
  var duration = moment.duration(end.diff(start));
  return duration.asHours();
};

const addMinutesPlayed = (sessStart, sessEnd) => {
  var start = moment(new Date(sessStart));
  var end = moment(new Date(sessEnd));
  var duration = moment.duration(end.diff(start));
  return duration.asMinutes();
};

export const {
  login,
  logout,
  loadChildren,
  handleUsername,
  handleEmail,
  handlePassword,
  handleChildUsername,
  handleChildId,
  handleGame,
  handleGamesData,
  handleSetupFilesData,
  updateGameData,
  handleGameSlug,
  handleChildOptions,
  loadGraphData,
  setLevels,
  setShowOutliers,
  setShowCentering,
  setSubscriptionExpired,
  handleLevel,
  handleSessionId,
  handleSessionTitle,
  selectLastSession,
  setAllSessions,
  setUserInfo,
  handleSessionScore,
  setSessions,
  cleanChildUsername,
  setServerMsg,
  cleanAuthFields,
  setTermsAndConditions,
} = userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const checkLogin =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/api/users/login-web-user`, {
        Email: email,
        Password: password,
      });
      if (res.data.Token) {
        dispatch(login(res.data));
        dispatch(checkSubscription())
          .then(() => {
            dispatch(getChildren());
            dispatch(setSubscriptionExpired(false));
          })
          .catch((err) => {
            if (err?.response?.data?.subscriptionExpired) {
              dispatch(setSubscriptionExpired(true));
            } else {
              dispatch(setSubscriptionExpired(false));
            }
          });
      }
    } catch (err) {
      dispatch(setServerMsg(err.response.data.error));
    }
  };

export const createAccount =
  ({ username, email, password }) =>
  async (dispatch) => {
    let user = {
      Username: username,
      Email: email,
      Password: password,
      Pin: "2021",
    };
    try {
      await axios.post(`${URL}/api/users/create-web-user`, user);
      dispatch(checkLogin({ email, password }));
    } catch (err) {
      dispatch(setServerMsg(err.response.data.error));
    }
  };

export const loadInitialData = () => async (dispatch) => {
  dispatch(checkSubscription())
    .then(() => {
      dispatch(getChildren());
      dispatch(loadChildrenActivity());
      dispatch(loadUserInfo());
      dispatch(setSubscriptionExpired(false));
    })
    .catch((err) => {
      if (err?.response?.data?.subscriptionExpired) {
        dispatch(setSubscriptionExpired(true));
      } else {
        dispatch(setSubscriptionExpired(false));
      }
    });
};

// This will return an error if the subscriiption has expired
export const checkSubscription = () => async () => {
  await axios.get(`${URL}/api/users/parent/get/children/sessions`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
  });
};

export const loadChildrenActivity = () => async (dispatch) => {
  try {
    const sessions = await axios.get(
      `${URL}/api/users/parent/get/children/sessions`,
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(setAllSessions(sessions.data.Sessions));
  } catch (err) {
    dispatch(setServerMsg(err.response.data.error));
  }
};

export const getChildren = () => async (dispatch) => {
  try {
    const children = await axios.post(
      `${URL}/api/users/parent/get/children`,
      {},
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(loadChildren(children.data.Children));
    dispatch(loadChildrenActivity());
  } catch (err) {
    dispatch(setServerMsg(err.response.data.error));
  }
};

export const loadUserInfo = () => async (dispatch) => {
  try {
    const userInfo = await axios.get(`${URL}/api/subscription`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
    });
    dispatch(setUserInfo(userInfo.data.results));
  } catch (err) {
    dispatch(setServerMsg(err.response.data.error));
  }
};

export const addChild =
  ({ childUsername, pin }) =>
  async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/api/users/create`, {
        Username: childUsername,
        Pin: pin,
      });
      dispatch(newChild(res));
      dispatch(cleanChildUsername());
    } catch (err) {
      dispatch(setServerMsg(err.response.data.error));
    }
  };

export const generateUsername = () => async (dispatch) => {
  const url = `${URL}/api/users/generate`;
  try {
    await axios.get(url).then((result) => {
      if (result) {
        dispatch(handleChildUsername(result.data.Username));
      } else {
        dispatch(setServerMsg("No user found"));
      }
    });
  } catch (err) {
    console.log(err);
    // dispatch(setServerMsg(err.response.data.error));
  }
};

export const loadSessions = (Id) => async (dispatch) => {
  try {
    const sessions = await axios.post(
      `${URL}/api/users/parent/get/child/sessions`,
      { Id },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(setSessions(sessions.data.Sessions.reverse()));
  } catch (err) {
    console.log(err);
  }
};

export const loadSessionData = (id, sessionId) => async (dispatch) => {
  //gustavo id = 132
  //bluedog75 id = 135
  try {
    const res = await axios.post(
      `${URL}/api/users/parent/get/child/session/data`,
      { Id: id, SessionId: sessionId },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(handleSessionId(sessionId));
    dispatch(loadGraphData(res.data.SessionData));
  } catch (err) {
    console.log(err);
    // dispatch(setServerMsg(err.response.data.error));
  }
};

export const loadLevelData = (id, session, level) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${URL}/api/users/parent/get/child/session/level/data`,
      { Id: id, SessionId: session, LevelId: level.Id },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    const my_data = { LevelType: level.LevelType, LevelData: [...res.data.LevelData ] }
    dispatch(loadGraphData(my_data));
    // let levelAccess = res.data.Levels.levelType === "STATIC" || "EMG";
    // console.log(levelAccess);
    dispatch(handleLevel(level));
  } catch (err) {
    console.log(err);
  }
};

//1. session entry > on clicking > loadLevels()
export const loadLevels = (id, session) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${URL}/api/users/parent/get/child/session/levels`,
      { Id: id, SessionId: session },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    // console.log(res.data.Levels);
    //TODO: replace by a single session object
    dispatch(handleSessionId(session));
    // dispatch(handleSessionTitle(session.Title));
    // dispatch(handleSessionScore(session.Result));
    dispatch(setLevels(res.data.Levels)); //levels = [data.Levels]
    let level = {
      ...res.data.Levels.find((level) => level === "EMG" || "STATIC"),
    };
    // console.log(level);
    dispatch(handleLevel(level)); //level = {1st STATIC}
    dispatch(loadLevelData(id, session, level));
  } catch (err) {
    console.log(err);
  }
};

const newChild = (res) => async (dispatch) => {
  try {
    await axios.post(
      `${URL}/api/users/parent/add/child`,
      {
        Username: res.data.result.username,
        Id: res.data.result.pin,
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(getChildren());
  } catch (err) {
    dispatch(setServerMsg(err.response.data.error));
  }
};

export const loadChildOptions = (childId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${URL}/api/users/parent/get/child/options`,
      { ChildId: childId },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(handleChildOptions(res.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateChildOptions = (childId, options) => async (dispatch) => {
  const { errorless, colourInvert, lowQuality, scale } = options;
  try {
    const res = await axios.post(
      `${URL}/api/users/parent/set/child/options`,
      {
        ChildId: childId,
        IsErrorless: errorless,
        IsColourInvert: colourInvert,
        IsLowQuality: lowQuality,
        Scale: scale,
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(handleChildOptions(res.data));
  } catch (err) {
    console.log(err);
  }
};

export const toggleActivatePlayer = (id, value) => async (dispatch) => {
  try {
    await axios.patch(
      `${URL}/api/users/parent/access/child`,
      { childId: id, value: value },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(getChildren());
  } catch (err) {
    console.log(err);
  }
};

export const loadGamesData = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${URL}/api/games/client`,
      {},
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(handleGamesData(res.data.Games));
  } catch (err) {
    console.log(err);
  }
};

export const loadSetupFilesData = () => async (dispatch) => {
  try {
    const res = await axios.get(`${URL}/api/games/client/setup/info`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
    });
    dispatch(handleSetupFilesData(res.data.Files));
  } catch (err) {
    console.log(err);
  }
};

const uploadGameFile = (gameFile, game) => async () => {
  try {
    const formData = new FormData();
    formData.append("game", game);
    formData.append("gameFile", gameFile);

    await axios.post(`${URL}/api/games/client/game`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const saveClientFiles =
  (client, updater, setup, versions) => async (dispatch) => {
    try {
      if ("size" in client && client.size > 0)
        dispatch(uploadClientFile(client, "client"));
      if ("size" in updater && updater.size > 0)
        dispatch(uploadClientFile(updater, "updater"));
      if ("size" in setup && setup.size > 0)
        dispatch(uploadClientFile(setup, "setup"));

      await axios.post(
        `${URL}/api/games/client/setup/info`,
        {
          client: { name: client.name, version: versions.client },
          updater: { name: updater.name, version: versions.updater },
          setup: { name: setup.name, version: versions.updater },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
      );
      dispatch(loadSetupFilesData());
    } catch (err) {
      console.log(err);
    }
  };

const uploadClientFile = (file, name) => async () => {
  try {
    const formData = new FormData();
    formData.append(name, file);

    await axios.post(`${URL}/api/games/client/${name}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const uploadMultipleFiles = (files, path) => async () => {
  try {
    const data = new FormData();
    Array.from(files).forEach((file) => {
      data.append("file", file);
    });
    await axios.post(`${URL}/api/games/client/${path}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const saveSingleGame =
  (game, gameFile, gameMedia, mediaFiles) => async (dispatch) => {
    try {
      if (Object.keys(mediaFiles).length > 0) {
        dispatch(uploadMultipleFiles(mediaFiles, "media"));
      }
      await axios.post(
        `${URL}/api/games/client/info`,
        { Game: game, Media: gameMedia, ZipFile: gameFile.name },
        { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
      );
      dispatch(uploadGameFile(gameFile, game));
      dispatch(loadGamesData());
    } catch (err) {
      console.log(err);
    }
  };

export const updateSingleGame =
  (game, gameFile, gameMedia, mediaFiles) => async (dispatch) => {
    try {
      if (Object.keys(mediaFiles).length > 0) {
        dispatch(uploadMultipleFiles(mediaFiles, "media"));
      }

      if ("size" in gameFile && gameFile.size > 0) {
        await axios.patch(
          `${URL}/api/games/client/game`,
          { Game: game, Media: gameMedia, ZipFile: gameFile.name },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        dispatch(uploadGameFile(gameFile, game));
      } else {
        await axios.patch(
          `${URL}/api/games/client/info`,
          { Game: game, Media: gameMedia },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          }
        );
      }
      dispatch(loadGamesData());
    } catch (err) {
      console.log(err);
    }
  };

export const sharePlayer = (email, player) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${URL}/api/users/parent/child/share`,
      { email, child: player },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(setServerMsg(res.data.message));
  } catch (err) {
    if (err.response.data) {
      dispatch(setServerMsg(err.response.data.message));
    } else {
      dispatch(setServerMsg("An error has occurred. Try again later"));
    }
  }
};

export const acceptTermsAndConditions = () => async (dispatch) => {
  try {
    await axios.patch(
      `${URL}/api/users/accept-terms`,
      { value: 1 },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    dispatch(setTermsAndConditions());
  } catch (err) {
    if (err.response.data) {
      dispatch(setServerMsg(err.response.data.message));
    } else {
      dispatch(setServerMsg("An error has occurred. Try again later"));
    }
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state) => state.user.counter;

export default userSlice.reducer;
