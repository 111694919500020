import React from "react";
import "./styles/GamesCard.css";
import GameEntry from "./GameEntry";

const games = [
  {
    id: 1,
    name: "Calibration",
    slug: "calibration",
    // image: "board.png",
    hours: "-",
    highest_score: "-",
  },
  {
    id: 2,
    name: "Fortune Seeker",
    slug: "runner-exer",
    // image: "runner.png",
    hours: 35,
    highest_score: 289,
  },
  {
    id: 3,
    name: "Tidal Treasures",
    slug: "exermaze",
    // image: "exermaze.png",
    hours: 12,
    highest_score: 189,
  },
  {
    id: 4,
    name: "Galaxy Fighter: Flex",
    slug: "spark-in-space",
    // image: "space.png",
    hours: 24,
    highest_score: 800,
  },
];

const GamesCard = () => {
  return (
    <div className="GamesCard card">
      <div className="header">
        <p className="container-title">Games</p>
        <span className="container-subtitle">
          Select a game to see the latest session
        </span>
      </div>
      <div className="games-header">
        <p>Game</p>
        <p>Minutes played</p>
        <p>Best score</p>
        <p></p>
      </div>
      <div className="games-list">
        {games.map((game) => (
          <GameEntry key={game.id} game={game} />
        ))}
      </div>
    </div>
  );
};

export default GamesCard;
