import React, { useState } from "react";
import ChartCard from "../components/ChartCard";
import RecentPlayersCard from "../components/RecentPlayersCard";
import UserCard from "../components/UserCard";
import ViewHeader from "../components/ViewHeader";
import { useDispatch, useSelector } from "react-redux";
import "./styles/Dashboard.css";
import TermsAndConditions from "../components/TermsAndConditions";
import { logout, acceptTermsAndConditions } from "../state/userSlice";

export default function Dashboard() {
  const hasAcceptedTerms = useSelector((state) => state.user.hasAcceptedTerms);
  const [toggle, setToggle] = useState(true);
  const dispatch = useDispatch();

  const closeModal = () => {
    setToggle(false);
    dispatch(logout());
  };

  return (
    <div className="Dashboard">
      <ViewHeader title="Dashboard" subtitle="Overview of all the data" />
      <div>
        <div className="containers">
          <UserCard />
          <div className="data-containers">
            <RecentPlayersCard />
            <ChartCard />
          </div>
        </div>
      </div>
      {!hasAcceptedTerms && (
        <TermsAndConditions
          action={() => dispatch(acceptTermsAndConditions())}
          closeModal={closeModal}
          toggle={toggle}
        />
      )}
    </div>
  );
}
