import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  checkLogin,
  handleEmail,
  handlePassword,
  cleanAuthFields,
  setSubscriptionExpired,
} from "../state/userSlice";
import { Link } from "react-router-dom";
import "./styles/Form.css";
import LoadingDots from "../components/LoadingDots";
import Modal from "../components/Modal";

function Login() {
  const { email, password, serverMsg, subscriptionExpired } = useSelector(
    (state) => ({
      email: state.user.email,
      password: state.user.password,
      serverMsg: state.user.serverMsg,
      subscriptionExpired: state.user.subscriptionExpired,
    }),
    shallowEqual
  );
  const [isLoading, setIsLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (subscriptionExpired) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [subscriptionExpired]);

  useEffect(() => {
    dispatch(cleanAuthFields());
  }, [dispatch]);

  const login = (e) => {
    setIsLoading(true);
    e.preventDefault();
    dispatch(checkLogin({ email, password })).then(() => {
      setIsLoading(false);
      if (subscriptionExpired) {
        setToggle(true);
      } else {
        setToggle(false);
      }
    });
  };

  const closeModal = () => {
    setToggle(false);
    dispatch(setSubscriptionExpired());
  };

  return (
    <div className="Login Form">
      <div className="main">
          <img id="logo" src="/images/newlogo.png" alt="Logo png" height={100} width={300} />
        <div className="form">
          <div className="form-container">
            <p className="form-title">Sign in to your account</p>
            <div className="form-content">
              <form onSubmit={login}>
                <div>
                  <div className="field">
                    <label htmlFor="email">Email</label>
                    <br />
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => dispatch(handleEmail(e.target.value))}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="password">Password</label>
                    <br />
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => dispatch(handlePassword(e.target.value))}
                    />
                    <span className="forgot-password">
                      <Link to="/reset">Forgot your password?</Link>
                    </span>
                  </div>
                </div>
                <button>{isLoading ? <LoadingDots /> : "Login"}</button>
                <p className="server-response">{serverMsg}</p>
              </form>
            </div>
          </div>
          <p className="no-account-signup">
            Don't have an account? <Link to="/signup">Sign up</Link>
          </p>
        </div>
      </div>
      <div className="footer">
        <a href="https://ubitechit.com/" target="_blank" rel="noreferrer">
          &copy; Ubitech {new Date().getFullYear()}
        </a>
      </div>
      <Modal closeModal={closeModal} toggle={toggle} />
    </div>
  );
}

export default Login;
