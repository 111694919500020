import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { saveClientFiles } from "../state/userSlice";
import "./styles/DevelopmentModal.css";
import "./styles/DevelopmentSetupModal.css";

const DevelopmentSetupModal = ({ toggle, closeModal, versions }) => {
  const [clientFile, setClientFile] = useState({});
  const [updaterFile, setUpdaterFile] = useState({});
  const [setupFile, setSetupFile] = useState({});
  const dispatch = useDispatch();
  const clientFilename = useRef("");
  const updaterFilename = useRef("");
  const setupFilename = useRef("");

  const saveChanges = () => {
    dispatch(saveClientFiles(clientFile, updaterFile, setupFile, versions));
    setClientFile({});
    setUpdaterFile({});
    setSetupFile({});
    close();
  };

  const handleFile = (e, type) => {
    switch (type) {
      case "client":
        setClientFile(
          appendVersion("client", e.target.files[0], "application/zip")
        );
        break;
      case "updater":
        setUpdaterFile(
          appendVersion("updater", e.target.files[0], "application/zip")
        );
        break;
      case "setup":
        setSetupFile(
          appendVersion("setup", e.target.files[0], "application/x-msdownload")
        );
        break;
      default:
    }
  };

  const appendVersion = (type, obj, fileType) => {
    let file = obj;
    let newName = file.name.split(".");
    let nameVersion =
      newName[0] + "_" + (versions[type] + 1) + "." + newName[1];
    var blob = file.slice(0, file.size, fileType);
    return new File([blob], nameVersion, {
      type: fileType,
    });
  };

  const close = () => {
    clientFilename.current.value = "";
    updaterFilename.current.value = "";
    setupFilename.current.value = "";
    closeModal();
  };

  return (
    <div
      className="DevelopmentModal AddPlayer DevelopmentSetupModal"
      style={{ display: toggle ? "flex" : "none" }}
    >
      <div className="modal-window">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Add Files</h2>
            <div className="close-modal" onClick={close}>
              <img src="/images/x.png" alt="X" />
            </div>
          </div>
          <div className="dev-form">
            <div className="form-section">
              <div className="field">
                <div className="username-container">
                  <label htmlFor="email">UTP Client</label>
                  <span className="file-type">.zip file</span>
                </div>
                <br />
                <input
                  type="file"
                  accept=".zip"
                  ref={clientFilename}
                  onChange={(e) => handleFile(e, "client")}
                />
              </div>
              <div className="field">
                <div className="username-container">
                  <label htmlFor="email">Updater File</label>
                  <span className="file-type">.zip file</span>
                </div>
                <br />
                <input
                  type="file"
                  accept=".zip"
                  ref={updaterFilename}
                  onChange={(e) => handleFile(e, "updater")}
                />
              </div>
              <div className="field">
                <div className="username-container">
                  <label htmlFor="email">Setup File</label>
                  <span className="file-type">.exe file</span>
                </div>
                <br />
                <input
                  type="file"
                  accept=".exe"
                  ref={setupFilename}
                  onChange={(e) => handleFile(e, "setup")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button id="cancel-btn" onClick={close}>
            Cancel
          </button>
          <button id="create-btn" onClick={saveChanges}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentSetupModal;
