import React, { useState, useEffect } from "react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { loadSetupFilesData } from "../state/userSlice";
import DevelopmentSetupModal from "../components/DevelopmentSetupModal";

const DevelopmentSetup = () => {
  const [toggleModal, setToggleModal] = useState(false);
  const [versions, setVersions] = useState({
    client: 1,
    updater: 1,
    setup: 1,
  });
  const setupFiles = useSelector((state) => state.user.setupFiles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSetupFilesData());
  }, [dispatch]);

  useEffect(() => {
    let allVersions = {};
    allVersions.client = getLatestVersion("client_version") || 0;
    allVersions.updater = getLatestVersion("updater_version") || 0;
    allVersions.setup = getLatestVersion("setup_version") || 0;
    setVersions(allVersions);
  }, [toggleModal]);

  const getLatestVersion = (name) => {
    for (let i = setupFiles.length - 1; i >= 0; i--) {
      if (setupFiles[i][name] > 0) {
        return setupFiles[i][name];
      }
    }
  };

  return (
    <div className="DevelopmentSetup">
      <div className="header">
        <div>
          <p className="container-title">Setup</p>
          <span className="container-subtitle">Client setup files</span>
        </div>
        <div className="add-game" onClick={() => setToggleModal(true)}>
          <FontAwesomeIcon icon={faUpload} color="#3f37c9" />
          <p>Add Files</p>
        </div>
      </div>
      <div className="list-games">
        <div className="list-header">
          <p>Client</p>
          <p>Updater</p>
          <p>Setup</p>
        </div>
        {setupFiles.map((setup) => (
          <div className="list-content" key={setup._id}>
            <p>{setup.client_path}</p>
            <p>{setup.updater_path}</p>
            <p>{setup.setup_path}</p>
          </div>
        ))}
      </div>
      <DevelopmentSetupModal
        toggle={toggleModal}
        closeModal={() => setToggleModal(false)}
        versions={versions}
      />
    </div>
  );
};

export default DevelopmentSetup;
