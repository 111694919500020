import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import LoadingDots from "../components/LoadingDots";
import {
  createAccount,
  handleEmail,
  handlePassword,
  handleUsername,
  cleanAuthFields,
} from "../state/userSlice";
import "./styles/Form.css";
import TermsAndConditions from "../components/TermsAndConditions";

const Signup = () => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passWarning, setPassWarning] = useState("");
  const [confirmWarning, setConfirmWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  const dispatch = useDispatch();
  const { username, email, password, serverMsg } = useSelector(
    (state) => ({
      username: state.user.username,
      email: state.user.email,
      password: state.user.password,
      serverMsg: state.user.serverMsg,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (password.length > 0 && password.length < 8) {
      setPassWarning("show-msg");
    } else {
      setPassWarning("");
    }
    if (confirmPassword.length > 0 && password !== confirmPassword) {
      setConfirmWarning("show-confirm-msg");
    } else {
      setConfirmWarning("");
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    dispatch(cleanAuthFields());
  }, [dispatch]);

  const checkPassword = () => {
    if (password.length > 0 && password.length < 8) {
      setPassWarning("show-msg-warning");
    } else {
      setPassWarning("");
    }
  };

  const showMsg = () => {
    if (password.length < 8) {
      setPassWarning("show-msg");
    }
  };

  const checkConfirm = () => {
    if (confirmPassword.length > 0 && password !== confirmPassword) {
      setConfirmWarning("show-confirm-warning");
    } else {
      setConfirmWarning("");
    }
  };

  const showConfirm = () => {
    if (confirmPassword.length > 0 && password !== confirmPassword) {
      setConfirmWarning("show-confirm-msg");
    }
  };

  const termsAndConditions = (e) => {
    e.preventDefault();
    setToggle(true);
  };

  const signUp = () => {
    setToggle(false);
    setIsLoading(true);
    dispatch(createAccount({ username, email, password })).then(() =>
      setIsLoading(false)
    );
  };

  const closeModal = () => {
    setToggle(false);
  };

  return (
    <div className="Signup Form">
      <div className="main">
        <div className="header">
          <img id="logo" src="/images/newlogo.png" alt="Logo png" />
        </div>
        <div className="form">
          <div className="form-container">
            <p className="form-title">Create a Therapy Reloaded account</p>
            <div className="form-content">
              <form>
                <div>
                  <div className="field">
                    <label htmlFor="name">Full name</label>
                    <br />
                    <input
                      type="text"
                      id="name"
                      value={username}
                      onChange={(e) => dispatch(handleUsername(e.target.value))}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="email">Email</label>
                    <br />
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => dispatch(handleEmail(e.target.value))}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="password">Password</label>
                    <br />
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onFocus={showMsg}
                      onBlur={checkPassword}
                      onChange={(e) => dispatch(handlePassword(e.target.value))}
                    />
                    <p className={`password-msg ${passWarning}`}>
                      Your password needs to be at least 8 characters long
                    </p>
                  </div>
                  <div className="field confirm-password">
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <br />
                    <input
                      type="password"
                      id="confirm-password"
                      value={confirmPassword}
                      onFocus={showConfirm}
                      onBlur={checkConfirm}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <p className={`password-msg ${confirmWarning}`}>
                      This does not match your password
                    </p>
                  </div>
                </div>
                <button onClick={termsAndConditions}>
                  {isLoading ? <LoadingDots /> : "Create Account"}
                </button>
                <p className="server-response">{serverMsg}</p>
              </form>
            </div>
          </div>
          <p className="no-account-signup">
            Have an account? <Link to="/login">Login</Link>
          </p>
        </div>
      </div>
      <div className="footer">
        <a href="https://ubitechit.com/" target="_blank" rel="noreferrer">
          &copy; Ubitech {new Date().getFullYear()}
        </a>
      </div>
      <TermsAndConditions
        action={signUp}
        closeModal={closeModal}
        toggle={toggle}
      />
    </div>
  );
};

export default Signup;
