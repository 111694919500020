import React, { useState } from "react";
import { Scatter, Line } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import LevelsCard from "./LevelsCard";
import { setShowOutliers, setShowCentering } from "../state/userSlice";
import "./styles/GraphCard.css";

const GraphCard = () => {
    const game = useSelector((state) => state.user.game);
    const graphData = useSelector((state) => state.user.graphData);
    const level = useSelector((state) => state.user.level);
    const showOutliers = useSelector((state) => state.user.showOutliers);
    const showCentering = useSelector((state) => state.user.showCentering);
    const sessionFound = useSelector((state) => state.user.sessionFound);
    const [showDatapoints, setShowDatapoint] = useState(false);
    const dispatch = useDispatch();
    // console.log(level);
    console.log(graphData);

    if (level.LevelType == "EMG") {
        const data = {
            datasets: [
                {
                    showLine: true,
                    borderWidth: 2,
                    pointBorderWidth: 3,
                    pointRadius: showDatapoints ? 2 : 0,
                    pointBackgroundColor: "#3a0ca3",
                    pointBorderColor: "#3a0ca3",
                    data: [...graphData],
                    fill: false,
                    borderColor: "rgba(75,192,192,1)",
                },
            ]
        };

        return (
            <div className="GraphCard">
                <div className="graph-header">
                    <h3>Player EMG Muscle Activation Graph - {level.Title}</h3>
                    <p>See insights on how player sways when playing {game}</p>
                </div>
                <LevelsCard />
                <div className="graph-options">
                    <div className="show-datapoints">
                        <label htmlFor="dot-size">
                            <input
                                type="checkbox"
                                id="dot-size"
                                onChange={() => setShowDatapoint(!showDatapoints)}
                                defaultChecked={showDatapoints}
                            />
                            Show datapoints
                        </label>
                    </div>
                    {/* <div className="show-outliers">
                        <label htmlFor="dot-outliers">
                            <input
                                type="checkbox"
                                id="dot-outliers"
                                onChange={() => dispatch(setShowOutliers(!showOutliers))}
                                defaultChecked={showOutliers}
                            />
                            Apply outliers
                        </label>
                    </div>
                    <div className="show-centering">
                        <label htmlFor="dot-center">
                            <input
                                type="checkbox"
                                id="dot-center"
                                onChange={() => dispatch(setShowCentering(!showCentering))}
                                defaultChecked={showCentering}
                            />
                            Centering
                        </label>
                    </div> */}
                </div>

                {sessionFound ? (
                    <div className="graph-graph">
                        {graphData && graphData.length > 1 && (
                            <Scatter
                                data={data} redraw={true}
                            />
                        ) }
                    </div>
                ) : (
                    <p className="no-data-found">No levels data found</p>
                )}
            </div>
        );
    } else {
        const data = {
            datasets: [
                {
                    showLine: true,
                    borderWidth: 2,
                    pointBorderWidth: 3,
                    pointRadius: showDatapoints ? 2 : 0,
                    pointBackgroundColor: "#3a0ca3",
                    pointBorderColor: "#3a0ca3",
                    data: [...graphData],
                    fill: false,
                    borderColor: "rgba(75,192,192,1)",
                },
            ]
        };
        return (
            <div className="GraphCard">
                <div className="graph-header">
                    <h3>Player Sway Graph - {level.Title}</h3>
                    <p>See insights on how player sways when playing {game}</p>
                </div>
                <LevelsCard />
                <div className="graph-options">
                    <div className="show-datapoints">
                        <label htmlFor="dot-size">
                            <input
                                type="checkbox"
                                id="dot-size"
                                onChange={() => setShowDatapoint(!showDatapoints)}
                                defaultChecked={showDatapoints}
                            />
                            Show datapoints
                        </label>
                    </div>
                    <div className="show-outliers">
                        <label htmlFor="dot-outliers">
                            <input
                                type="checkbox"
                                id="dot-outliers"
                                onChange={() => dispatch(setShowOutliers(!showOutliers))}
                                defaultChecked={showOutliers}
                            />
                            Apply outliers
                        </label>
                    </div>
                    <div className="show-centering">
                        <label htmlFor="dot-center">
                            <input
                                type="checkbox"
                                id="dot-center"
                                onChange={() => dispatch(setShowCentering(!showCentering))}
                                defaultChecked={showCentering}
                            />
                            Centering
                        </label>
                    </div>
                </div>

              
                {sessionFound ? (
                    <div className="graph-graph">
                        {graphData && graphData.length > 1 && (
                            <Scatter
                                data={data} redraw={true} height={180} width={500}
                                options={ {
                                    scales: {
                                        yAxes: [ {
                                            ticks: {
                                                // reverse: true,
                                                suggestedMin: -1,
                                                suggestedMax: 1,
                                                stepSize: 0.1,
                                            }, scaleLabel: {
                                                display: true,
                                                labelString: "Forward & Backward",
                                            },
                                        }, ],
                                        xAxes: [ {
                                            ticks: {
                                                // reverse: true,
                                                suggestedMax: 1,
                                                suggestedMin: -1,
                                            }, scaleLabel: {
                                                display: true,
                                                labelString: "Sideways",
                                            },
                                        }, ],
                                    }, maintainAspectRatio: false,
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <p className="no-data-found">No levels data found</p>
                )}

            </div>
        );
    };
};

export default GraphCard;
