import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheckCircle,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { toggleActivatePlayer } from "../state/userSlice";
import "./styles/PlayerOptionsMenu.css";

const PlayerOptionsMenu = ({
  isOpen,
  wrapperRef,
  playerId,
  close,
  sharePlayer,
  isActive,
}) => {
  const dispatch = useDispatch();

  const optionSelected = (id, value) => {
    dispatch(toggleActivatePlayer(id, value));
    close();
  };

  return (
    <div
      ref={wrapperRef}
      className="PlayerOptionsMenu"
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <div className="menu-contents">
        <ul>
        {
          (!isActive) ? //is the player deactivated?
          <li onClick={() => optionSelected(playerId, 1)}>
            <FontAwesomeIcon icon={faCheckCircle} />
            Activate
          </li>
          :
          <li onClick={() => optionSelected(playerId, 0)}>
            <FontAwesomeIcon icon={faTimesCircle} />
            Deactivate
          </li>
        }
          <li
            onClick={() => {
              sharePlayer();
              close();
            }}
          >
            <FontAwesomeIcon icon={faShare} />
            Share
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PlayerOptionsMenu;
