import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import PlayerOptionsMenu from "./PlayerOptionsMenu";
import { useDispatch } from "react-redux";
import { handleChildId } from "../state/userSlice";
import moment from "moment";
import "./styles/PlayerEntry.css";
import { Link } from "react-router-dom";

const PlayerEntry = ({ player, sharePlayer }) => {
  const [toggleOptionsMenu, setToggleOptionsMenu] = useState(false);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  const openMenu = () => {
    setToggleOptionsMenu((prevState) => {
      return { ...prevState };
    });
  };

  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setToggleOptionsMenu(false);
    }
  }
  document.addEventListener("mousedown", handleClickOutside);

  function minutesToTime(time) {
    var hours = Math.trunc(time / 60);
    var minutes = time % 60;
    return hours > 0
      ? hours + "h " + Math.floor(minutes) + "min"
      : Math.floor(minutes) > 0
      ? Math.floor(minutes) + "min"
      : "-";
  }

  return (
    <div className="PlayerEntry">
      <div className={`player-name ${player.IsActive === 0 ? "deactive" : ""}`}>
        <Link
          to={{
            pathname: `/player/${player.Username}`,
            state: player.Username,
          }}
          onClick={() => dispatch(handleChildId(player.Id))}
        >
          <span className="player-icon">{player.Username.substr(0, 1)}</span>
          <div>
            <p>{player.Username}</p>
          </div>
        </Link>
      </div>
      <div
        className={`player-progress ${player.IsActive === 0 ? "deactive" : ""}`}
      >
        <div className="played-time">
          {player.lastSession !== "" ? (
            //calculate last time played (time ago)
            <span>Played {moment(new Date(player.lastSession)).fromNow()}</span>
          ) : (
            <span>-</span>
          )}
        </div>
      </div>
      <div className="player-hours">
        <p>{minutesToTime(player.totalMinutes)}</p>
      </div>
      <div className="player-options-container">
        <div className="player-options">
          <FontAwesomeIcon icon={faEllipsisH} onClick={openMenu} />
        </div>
        <PlayerOptionsMenu
          wrapperRef={wrapperRef}
          isOpen={toggleOptionsMenu}
          close={setToggleOptionsMenu}
          playerId={player.Id}
          isActive={player.IsActive}
          sharePlayer={() => sharePlayer(player)}
        />
      </div>
    </div>
  );
};

export default PlayerEntry;
