import React from "react";
import PlayersCard from "../components/PlayersCard";
import ViewHeader from "../components/ViewHeader";

export default function Players() {
  return (
    <div>
      <ViewHeader title="Players" subtitle="Overview of all the players" />
      <PlayersCard />
    </div>
  );
}
