import React, { useEffect } from "react";
import ChildCard from "../components/ChildCard";
import GamesCard from "../components/GamesCard";
import ViewHeader from "../components/ViewHeader";
import { handleChildUsername } from "../state/userSlice";
import { useDispatch, useSelector } from "react-redux";
import "./styles/Player.css";
import SessionsCard from "../components/SessionsCard";

const Player = (props) => {
  const childUsername = useSelector((state) => state.user.childUsername);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleChildUsername(props.location.state));
  }, [dispatch, props]);

  return (
    <div className="Player">
      <ViewHeader
        // title={props.location.state}
        title={childUsername}
        subtitle="Overview of the player's data"
      />
      <div className="player-content">
        <ChildCard />
        <div className="games-sessions">
          <GamesCard />
        </div>
      </div>
      <SessionsCard />
    </div>
  );
};

export default Player;
