import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadLevelData } from "../state/userSlice";
import "./styles/LevelsCard.css";

const LevelsCard = () => {
  const levels = useSelector((state) => state.user.levels);
  const lev = useSelector((state) => state.user.level);
  const childId = useSelector((state) => state.user.childId);
  const sessionId = useSelector((state) => state.user.session);
  const dispatch = useDispatch();

  return (
    <div className="LevelsCard">
      {levels &&
        levels.map((level) => (
          <p
            key={level.Id}
            onClick={() => dispatch(loadLevelData(childId, sessionId, level))}
            className={level.Title === lev.Title ? "level-active" : ""}
          >
            {level.Title}
          </p>
        ))}
    </div>
  );
};

export default LevelsCard;
