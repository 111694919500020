import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./styles/SessionEntry.css";
import { loadLevels, handleGame } from "../state/userSlice";

const SessionEntry = ({ session }) => {
  const { game, childUsername, childId } = useSelector(
    (state) => ({
      game: state.user.game,
      childUsername: state.user.childUsername,
      childId: state.user.childId,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const selectSession = () => {
    dispatch(loadLevels(childId, session.Id));
    dispatch(handleGame(session.Title));
  };

  return (
    <div className="SessionEntry">
      <p className="session-date">
        {/* {new Date(session.Start).toLocaleDateString()} //05/11/2020 */}
        {new Date(session.Start).toLocaleString()}
      </p>
      <Link
        to={{
          pathname: `/player/${childUsername}/session/${session.Id}`,
          state: game,
        }}
        className="session-id"
        onClick={() => selectSession()}
      >
        {session.Title}
      </Link>
      <p>{session.Result ? session.Result : "-"}</p>
    </div>
  );
};

export default SessionEntry;
