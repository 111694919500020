import React, { useState } from "react";
import ViewHeader from "../components/ViewHeader";
import "./styles/Development.css";
import DevelopmentGames from "../subviews/DevelopmentGames";
import DevelopmentSetup from "../subviews/DevelopmentSetup";

const Development = () => {
  const [tab, setTab] = useState("Games");

  return (
    <div className="Development">
      <ViewHeader title="Development" subtitle="Add games and their assets" />
      <div className="development-container card">
        <div className="header-tabs">
          <p
            className={tab === "Games" ? "active-tab games-tab" : "games-tab"}
            onClick={() => setTab("Games")}
          >
            Games
          </p>
          <p
            className={tab === "Setup" ? "active-tab" : ""}
            onClick={() => setTab("Setup")}
          >
            Setup
          </p>
        </div>
        {tab === "Games" ? <DevelopmentGames /> : <DevelopmentSetup />}
      </div>
    </div>
  );
};

export default Development;
