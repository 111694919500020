import React, { useState, useEffect } from "react";
import { faUpload, faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import DevelopmentModal from "../components/DevelopmentModal";
import { loadGamesData } from "../state/userSlice";

const gameModel = {
  GameId: "",
  Title: "",
  Description: "",
  Files: {
    FileId: "",
    Version: "",
    Url: "",
    ExePath: "",
  },
  Media: [],
  ZipFile: "",
  // ExeFilename: "",
};
//Games: [Description, Files:[FileId, ExePath, Url, Version], GameId, Media:[], Title]

const DevelopmentGames = () => {
  const games = useSelector((state) => state.user.gamesData);
  const [toggleUpdateGame, setToggleUpdateGame] = useState(false);
  const [modalType, setModalType] = useState("");
  const [game, setGame] = useState(gameModel);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadGamesData());
  }, [dispatch]);

  const openModal = (type, game) => {
    setModalType(type);
    setToggleUpdateGame(true);
    setGame(game);
  };

  const handleGame = (value, field) => {
    let tempGame = game;
    if (field === "Title" || field === "Description") {
      tempGame = {
        ...tempGame,
        [field]: value,
      };
    } else {
      tempGame = {
        ...tempGame,
        Files: {
          ...tempGame.Files,
          [field]: value,
        },
      };
    }
    setGame(tempGame);
  };

  return (
    <div className="DevelopmentGames">
      <div className="header">
        <div>
          <p className="container-title">Games</p>
          <span className="container-subtitle">List of games</span>
        </div>
        <div
          className="add-game"
          onClick={() => openModal("Add Game", gameModel)}
        >
          <FontAwesomeIcon icon={faUpload} color="#3f37c9" />
          <p>Add Game</p>
        </div>
      </div>
      <div className="list-games">
        <div className="list-header">
          <p>Title</p>
          <p>Version</p>
          <p>Game Files</p>
          <p>Media</p>
          <div></div>
        </div>
        {games.map((game) => (
          <div className="list-content" key={game.GameId}>
            <p>{game.Title}</p>
            <p>{game.Files.Version}</p>
            <p>{game.Files.ExePath}</p>
            <p>{game.Media.length}</p>
            <FontAwesomeIcon
              icon={faWrench}
              color="#3f37c9"
              onClick={() => openModal("Update Game", game)}
            />
          </div>
        ))}
      </div>
      <DevelopmentModal
        game={game}
        handleGame={handleGame}
        toggle={toggleUpdateGame}
        type={modalType}
        closeModal={() => setToggleUpdateGame(false)}
      />
    </div>
  );
};

export default DevelopmentGames;
