import React, { useState, useEffect } from "react";
import "./styles/SharePlayerModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import LoadingDots from "./LoadingDots";
import { sharePlayer, setServerMsg } from "../state/userSlice";
import { useDispatch, useSelector } from "react-redux";

const SharePlayerModal = ({ toggle, closeModal, userShare }) => {
  const serverMsg = useSelector((state) => state.user.serverMsg);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (serverMsg === "success") {
      setIsSuccessful(true);
    }
  }, [serverMsg]);

  const share = () => {
    if (email !== "") {
      setIsLoading(true);
      dispatch(sharePlayer(email, userShare));
      setIsLoading(false);
    }
  };

  const close = () => {
    setEmail("");
    dispatch(setServerMsg(""));
    closeModal();
  };

  return (
    <div
      className="SharePlayerModal AddPlayer"
      style={{ display: toggle ? "flex" : "none" }}
    >
      <div
        className="modal-window"
        style={{ display: serverMsg === "success" ? "none" : "block" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2>Share Player</h2>
            <div className="close-modal" onClick={close}>
              <img src="/images/x.png" alt="Close dialog" title="Close" />
            </div>
          </div>
          <p className="share-info">
            <span>{userShare.Username}</span> will be shared
          </p>
          <div className="field">
            <div className="username-container">
              <label htmlFor="email">Email</label>
            </div>
            <br />
            <input
              type="text"
              value={email}
              placeholder="Email of user you want to share with"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div
            className="message"
            style={{ color: serverMsg === "success" ? "#0fdd43" : "#eb2678" }}
          >
            {serverMsg}
          </div>
        </div>
        <p className="disclaimer">Only share with people you trust</p>
        <div className="modal-footer">
          <button id="cancel-btn" onClick={close}>
            Cancel
          </button>
          <button id="create-btn" onClick={share}>
            Share
          </button>
        </div>
        {isLoading && (
          <div className="loading">
            <LoadingDots />
          </div>
        )}
      </div>
      {isSuccessful && (
        <div className="is-successful modal-window">
          <FontAwesomeIcon icon={faCheck} />
          <p>Player shared successfully</p>
          <div className="modal-footer">
            <button
              id="create-btn"
              onClick={() => {
                setIsSuccessful(false);
                close();
              }}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SharePlayerModal;
