import React, { useEffect } from "react";
import { Bar, /*Line, Pie,*/ defaults } from "react-chartjs-2";
import { useSelector, shallowEqual } from "react-redux";
import useWindowSize from "../utils/useWindowSize";

//Charts.js
//https://www.chartjs.org/docs/latest/axes/
//https://github.com/reactchartjs/react-chartjs-2

//Chart settings
defaults.global.tooltips.enabled = false;
defaults.global.legend.display = false;

const ChartCard = () => {
  const [width] = useWindowSize();
  const { chartMonths } = useSelector(
    (state) => ({
      chartMonths: state.user.chartMonths,
    }),
    shallowEqual
  );

  return (
    <div className="ChartCard card">
      <div className="header">
        <p className="container-title">Chart</p>
        <span className="container-subtitle">Total minutes played</span>
        <Bar
          data={{
            labels: [...Object.keys(chartMonths)],
            datasets: [
              {
                label: "Hours played",
                data: [...Object.values(chartMonths)],
                backgroundColor: "#22B692",
              },
            ],
          }}
          height={180}
          width={width}
          options={{
            maintainAspectRatio: false,
            legend: {
              labels: {
                fontColor: "#7678A8",
                // fontSize: 5,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ChartCard;
