import React from "react";
import "./styles/Modal.css";

const Modal = ({ toggle, closeModal }) => {
  return (
    <div className="Modal" style={{ display: toggle ? "flex" : "none" }}>
      <div className="modal-window">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Subscription ended</h2>
            <div className="close-modal" onClick={closeModal}>
              <img src="/images/x.png" alt="X" />
            </div>
          </div>
          <div>
            <p>
              Your subscription has ended. Please email us on{" "}
              <a href="mailto:info@ubitechit.com">info@ubitechit.com</a> to
              renew it
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button id="cancel-btn" onClick={closeModal}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
