import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./styles/ViewHeader.css";

const ViewHeader = ({ title, subtitle }) => {
  return (
    <div className="ViewHeader">
      <div>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
      <div className="download-app">
        <a href="https://api.ubitheraplay.com/setup/UTPClientSetup.exe">
          <FontAwesomeIcon icon={faDownload} />
          <p>Download App</p>
        </a>
      </div>
    </div>
  );
};

export default ViewHeader;
