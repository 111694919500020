import outliers from "outliers";

const calculateMean = (array) => {
  if (array.length === 0) return 0; // Handle empty array case
  // Calculate the sum of all elements
  const sum = array.reduce((acc, val) => acc + val, 0);
  // Calculate the mean
  const mean = sum / array.length;
  return mean;
}

const processGraphData = (data, showOutliers, showCentering) => {
    // console.log(data)
    if (data.LevelType === "EMG") {
        let emgData = data.LevelData.map((d, index) => {
          let Y = 0.0;
          if (d.Emg_Processed === 0) {
            Y = calculateMean([d.Emg_Raw_Channel_0, d.Emg_Raw_Channel_1, d.Emg_Raw_Channel_2, d.Emg_Raw_Channel_3, d.Emg_Raw_Channel_4, d.Emg_Raw_Channel_5,
              d.Emg_Raw_Channel_6, d.Emg_Raw_Channel_7, d.Emg_Raw_Channel_8
            ])
          } else {
            Y = d.Emg_Processed
          }
          return ({
            x: index,
            y: Y
        })})
        return emgData;
    } else {
        let x = 0;
        let y = 0;
        let xAvg = 0;
        let yAvg = 0;
        let newData = [];
        let results = [];

        if (showOutliers) {
          newData = applyOutliers(data.LevelData);
        } else {
          // eslint-disable-next-line
          newData = data.LevelData.filter((d) => {
            if (
              d.Board_X >= -1.0 &&
              d.Board_X <= 1.0 &&
              d.Board_Y >= -1.0 &&
              d.Board_Y <= 1.0
            ) {
              return d;
            }
          });
        }
        if (showCentering) {
          [x, y] = applyCentering(newData, x, y);
        }

        xAvg = x === 0 ? 0 : x / newData.length;
        yAvg = y === 0 ? 0 : y / newData.length;
        results = newData.map((d) => ({
          x: d.Board_X - xAvg,
          y: d.Board_Y - yAvg,
        }));
        return results;
    }
};

function applyOutliers(data) {
  let newData = data.filter(outliers("Board_X"));
  newData = newData.filter(outliers("Board_Y"));
  return newData;
}

function applyCentering(newData, x, y) {
  newData.forEach((d) => {
    x += d.Board_X;
    y += d.Board_Y;
  });
  return [x, y];
}

//TODO: test data with this function
function filterOutliers(someArray) {
  // Copy the values, rather than operating on references to existing values
  var values = someArray.concat();

  // Then sort
  values.sort(function (a, b) {
    return a - b;
  });

  /* Then find a generous IQR. This is generous because if (values.length / 4)
   * is not an int, then really you should average the two elements on either
   * side to find q1.
   */
  var q1 = values[Math.floor(values.length / 4)];
  // Likewise for q3.
  var q3 = values[Math.ceil(values.length * (3 / 4))];
  var iqr = q3 - q1;

  // Then find min and max values
  var maxValue = q3 + iqr * 1.5;
  var minValue = q1 - iqr * 1.5;

  // Then filter anything beyond or beneath these values.
  var filteredValues = values.filter(function (x) {
    return x <= maxValue && x >= minValue;
  });

  // Then return
  return filteredValues;
}

export default processGraphData;
