import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "../views/Dashboard";
// import Games from "../views/Games";
import Players from "../views/Players";
import Profile from "../views/Profile";
import Development from "../views/Development";
import Login from "../views/Login";
import Signup from "../views/Signup";
import Navigation from "./Navigation";
import ResetPassword from "../views/ResetPassword";
import "../App.css";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../state/userSlice";
import Player from "../views/Player";
import Game from "../views/Game";

export default function Routes() {
  const isLogged = useSelector((state) => state.user.isLogged);
  const subscriptionExpired = useSelector(
    (state) => state.user.subscriptionExpired
  );
  const dispatch = useDispatch();

  const PrivateRoute = ({ component: Component, ...rest }) => {
    if (subscriptionExpired) {
      dispatch(logout());
      return <Login />;
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          isLogged ? (
            <div className="App">
              <Navigation />
              <main>
                <Component {...props} />
              </main>
            </div>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  };

  const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          isLogged && restricted ? (
            <Redirect to={props.path} />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  };
  return (
    <div>
      <Switch>
        <PublicRoute restricted={true} component={Login} path="/login" exact />
        <PublicRoute
          restricted={true}
          component={ResetPassword}
          path="/reset"
          exact
        />
        <PublicRoute
          restricted={true}
          component={Signup}
          path="/signup"
          exact
        />
        <PrivateRoute component={Dashboard} path="/" exact />
        {/* <PrivateRoute component={Games} path="/games" exact /> */}
        <PrivateRoute component={Players} path="/players" exact />
        <PrivateRoute component={Profile} path="/profile" exact />
        <PrivateRoute component={Development} path="/development" exact />

        <PrivateRoute component={Player} path="/player/:username" exact />
        <PrivateRoute component={Game} path="/player/:username/:game" exact />
        <PrivateRoute
          component={Game}
          path="/player/:username/session/:session"
          exact
        />
      </Switch>
    </div>
  );
}
