import React from "react";
import "./styles/SmallCard.css";

const SmallCard = ({ title, data, footer }) => {
  const colour = () => {
    if (footer.includes("up")) {
      return "good";
    } else {
      return "bad";
    }
  };

  return (
    <div className="SmallCard">
      <p>{title}</p>
      <span className="data">{data && data.toFixed(2)}</span>
      <span className={`footer ${colour()}`}>{footer}</span>
    </div>
  );
};

export default SmallCard;
