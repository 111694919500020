import React from "react";
import { Link } from "react-router-dom";
import "./styles/Form.css";
import "./styles/ResetPassword.css";

const ResetPassword = () => {
  return (
    <div className="ResetPassword Form">
      <div className="main">
        <div className="header">
          <h1>UBITECH</h1>
          <h2>TheraPlay</h2>
        </div>
        <div className="form">
          <div className="form-container">
            <p className="form-title">Reset your password</p>
            <div className="form-content">
              <p className="reset-msg">
                Enter the email address associated with your account and we will
                send you a password reset link
              </p>
              <form>
                <div>
                  <div className="field">
                    <label htmlFor="email">Email</label>
                    <br />
                    <input type="email" id="email" />
                  </div>
                </div>
                <button>Send link</button>
                <p className="return-login">
                  <Link to="/login">Return to login</Link>
                </p>
              </form>
            </div>
          </div>
          <p className="no-account-signup">
            Don't have an account? <Link to="/signup">Sign up</Link>
          </p>
        </div>
      </div>
      <div className="footer">
        <a href="https://ubitechit.com/" target="_blank" rel="noreferrer">
          &copy; Ubitech {new Date().getFullYear()}
        </a>
      </div>
    </div>
  );
};

export default ResetPassword;
