import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadChildOptions, updateChildOptions } from "../state/userSlice";
import "./styles/ChildCard.css";
import ToggleOption from "./ToggleOption";

function ChildCard() {
  const childUsername = useSelector((state) => state.user.childUsername);
  const childId = useSelector((state) => state.user.childId);
  const options = useSelector((state) => state.user.childOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadChildOptions(childId));
  }, [dispatch, childId]);

  return (
    <div className="ChildCard UserCard">
      <div className="header">
        <span className="profile-pic">{childUsername.substr(0, 1)}</span>
        <div className="profile-info">
          <h3>{childUsername}</h3>
          <h4>Player</h4>
        </div>
      </div>
      <div className="profile-data">
        {/* <div>
          <p>Managed by</p>
          <span>2</span>
        </div>
        <div>
          <p>Games</p>
          <span>3</span>
        </div>
        <div>
          <p>Boards</p>
          <span>2</span>
        </div> */}
      </div>
      <span className="separator"></span>
      <div className="profile-section">
        <p className="subtitle">Player Options</p>
        <div className="content-container">
          <ToggleOption
            setting="Errorless Play"
            description="Errorless play disables all the game scenarios where a player can
              lose"
            option="errorless"
            isOn={options.errorless}
          />
          <ToggleOption
            setting="Invert Colours"
            description="Invert the colours of the games"
            option="colourInvert"
            isOn={options.colourInvert}
          />
          <ToggleOption
            setting="Visual Quality"
            description="Reduce the quality of the game graphics and reduce distrations"
            option="lowQuality"
            isOn={options.lowQuality}
          />
          <div className="child-setting setting-slide">
            <span className="info">Scale</span>
            <input
              type="range"
              min="1.0"
              max="2.0"
              step="0.1"
              value={options.scale}
              onChange={(e) =>
                dispatch(
                  updateChildOptions(childId, {
                    ...options,
                    scale: e.target.value,
                  })
                )
              }
            />
            <span>{parseInt(options.scale * 100 - 100)}%</span>
            <span className="setting-description">
              Set the scale of certain game objects to make them more visible
              during gameplay
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChildCard;
