import React, { useEffect } from "react";
import { loadInitialData } from "./state/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./common/Routes";

function App() {
  const isLogged = useSelector((state) => state.user.isLogged);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogged) {
      dispatch(loadInitialData());
    }
  }, [isLogged, dispatch]);

  return (
    <Router>
      <div>
        <Routes />
      </div>
    </Router>
  );
}

export default App;
