import React, { useState, useEffect } from "react";
import "./styles/AddPlayer.css";
import { useDispatch, useSelector } from "react-redux";
import {
    addChild,
    generateUsername,
    handleChildUsername,
} from "../state/userSlice";

const AddPlayer = ({ toggle, closeModal }) => {
    const childUsername = useSelector((state) => state.user.childUsername);
    const dispatch = useDispatch();
    const [pin, setPin] = useState("1234");

    useEffect(() => {
        dispatch(handleChildUsername(""));
    }, [dispatch]);

    const addPlayer = () => {
        if (childUsername !== "") {
            dispatch(addChild({ childUsername, pin }));
            closeModal();
        }
    };

    return (
        <div className="AddPlayer" style={{ display: toggle ? "flex" : "none" }}>
            <div className="modal-window">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Add Player</h2>
                        <div className="close-modal" onClick={closeModal}>
                            <img src="/images/x.png" alt="X" />
                        </div>
                    </div>
                  <div className="field">
                      <div className="username-container">
                          <label htmlFor="email">Username</label>
                          <span
                              className="generate-label"
                              onClick={() => dispatch(generateUsername())}>
                              Generate username
                          </span>
                      </div>
                      <br />
                      <input
                          autoComplete="off"
                          type="text"
                          title="If you are a therapist please use the 'generate username' button"
                          value={childUsername}
                          placeholder="Enter username or 'generate' one"
                          onChange={(e) => dispatch(handleChildUsername(e.target.value))}
                      />
                      </div>
                      {/* <div className="field">
                        <label htmlFor="email">Pin</label>
                        <br />
                        <input
                          type="password"
                          title="Not Required"
                          autoComplete="off"
                          placeholder="Optional"
                          value={pin}
                          onChange={(e) => setPin(e.target.value)}
                        />
                      </div> */}
                  </div>
                  <div className="modal-footer">
                      <button id="cancel-btn" onClick={closeModal}>
                          Cancel
                      </button>
                      <button id="create-btn" onClick={addPlayer}>
                          Create
                      </button>
                  </div>
            </div>
        </div>
    );
};

export default AddPlayer;
