import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "./styles/ProfileCard.css";
import { CheckboxOption } from "./CheckboxOption";

const ProfileCard = () => {
  const username = useSelector((state) => state.user.username);
  const userInfo = useSelector((state) => state.user.userInfo);
  const [notifications, setNotifications] = useState({
    account: true,
    subscription: true,
    marketing: false,
  });

  return (
    <div className="ProfileCard card">
      <div className="header">
        <p className="container-title">Account</p>
        <span className="container-subtitle">
          Profile information and settings
        </span>
      </div>
      <div className="account-info">
        <div className="profile-info">
          <h4>Account Firstname and Lastname</h4>
          <span className="username-capitals">{username}</span>
          <h4>Account created</h4>
          <span>{moment(userInfo.createdAt).format("D MMMM YYYY")}</span>
        </div>
        <div className="account-section">
          <h3>Subscription</h3>
          <span className="account-subtitle">
            Details about your subscription
          </span>
          <div className="profile-info">
            <h4>Subscription type</h4>
            <span>{userInfo.subscription.subscriptionType}</span>
            <span className="upgrade-subscription">
              To upgrade your subscription email us at{" "}
              <a href="mailto:info@ubitechit.com">info@ubitechit.com</a>
            </span>
            <h4>Start date</h4>
            <span>
              {moment(userInfo.subscription.start).format("D MMMM YYYY")}
            </span>
            <h4>End date</h4>
            <span>
              {moment(userInfo.subscription.end).format("D MMMM YYYY")}
            </span>
          </div>
        </div>
        <div className="account-section">
          <h3>Notifications</h3>
          <span className="account-subtitle">
            Choose the type of notifications your would like to receive from
            Ubitech
          </span>
          <div className="notification-options">
            <CheckboxOption
              title="Account and users"
              checked={notifications.account}
              onChecked={() => setNotifications(!notifications.account)}
            />
            <CheckboxOption
              title="Subscription"
              checked={notifications.subscription}
            />
            <CheckboxOption
              title="Marketing"
              checked={notifications.marketing}
            />
          </div>
          <p className="extra-info">*These will be sent to you via email</p>
        </div>
        {/* <div className="account-section">
          <h3>Settings</h3>
          <span>All available settings</span>
          <div className="content-container">
            <ToggleOption
              setting="Public profile"
              description="Show or hide your profile"
              option="public"
              isOn={true}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ProfileCard;
